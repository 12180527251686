import { Controller } from "stimulus"
import _ from 'underscore';
import '../../../../assets/javascripts/vendor/bootstrap-datepicker';
import '../../../../assets/stylesheets/vendor/datepicker3.css';
import moment from 'moment';

const booking_calendar_data_v2 = {}

export default class extends Controller {
  static targets = ['priceContainer', 'resultHolder', 'fees', 'totalVat', 'feeVat', 'paymentVat', 'price', "from", 'to', 'totalPrice', 'days', 'availabilityLink', 'checkAvailabilityLink', 'bookingButton']

  fetch_period_availability(start_date, end_date) {
    var that = this
    const fetchAvailability = _.debounce(function () {
      var url = that.availabilityLinkTarget.dataset.url
      if (start_date && end_date) {
        var start = moment(start_date).startOf('day').add(12, 'hours').unix();
        var end = moment(end_date).startOf('day').add(12, 'hours').unix();

        $.get(url, { start: start, end: end }).done(function (data) {
          data.forEach(d => booking_calendar_data_v2[d.day] = d)
          that.onAvailabilityUpdated();
        });
      }
    }, 100, true)
    fetchAvailability();
  }

  is_available(date) {
    // be careful when checking status, BookingCalendarSerializer mixes status with status_reason when false
    var event = booking_calendar_data_v2[moment(date).format('YYYY-MM-DD')];
    if (event === undefined) {
      return false;
    } else {
      return event.status === true;
    }
  }

  beforeShowDay(date) {
    var d = moment(date);
    var needsFetch = booking_calendar_data_v2[d.format('YYYY-MM-DD')] === undefined;

    if (d <= moment()) {
      // dates in the past never selectable in bookings
      return [false, ''];
    }

    if (needsFetch) {
      var start = moment(date).subtract(90, 'days');
      var end = moment(date).add(90, 'days');
      this.fetch_period_availability(start, end);
      return [false, ''];
    }

    if (this.is_available(d)) {
      var date1 = $(this.fromTarget).datepicker("getDate");
      var date2 = $(this.toTarget).datepicker("getDate");

      var cssStyle = date1 && ((date.getTime() == date1.getTime()) || (date2 && date >= date1 && date <= date2)) ? "availability-highlight" : "";
      return [true, cssStyle];
    } else {
      return [false, ''];
    }
  }

  onAvailabilityUpdated() {
    $(this.toTarget).datepicker("refresh");
    $(this.fromTarget).datepicker("refresh");
  };

  on_period_chosen(start_date, end_date) {
    const that = this
    if (start_date && end_date) {
      var start = moment(start_date).startOf('day').add(12, 'hours').unix();
      var end = moment(end_date).startOf('day').add(12, 'hours').unix();
      $.get(this.checkAvailabilityLinkTarget.dataset.url, { start: start, end: end }).done(function (data) {
        if (data.errors) {
          const resultHTML = `<div class="alert alert-danger"><ul>${data.errors.map(e => `<li>${e}</li>`)}</ul></div>`
          $(that.bookingButtonTarget).attr('disabled', true)
          // that.resultHolderTarget.innerHTML = resultHTML
          // $(that.resultHolderTarget).find('.alert-success').addClass('d-none')
        }
        else {
          if (data.display_price) {
            that.priceTarget.innerHTML = that.withCurrency(data.price, data.currency)
            that.paymentVatTarget.innerHTML = that.withCurrency(data.rent_fee_vat, data.currency)
            that.feeVatTarget.innerHTML = that.withCurrency(data.service_fee_vat, data.currency)
            that.totalVatTargets.forEach(t => t.innerHTML = that.withCurrency(data.service_fee_vat + data.rent_fee_vat, data.currency))
            that.feesTarget.innerHTML = that.withCurrency(data.total_fee, data.currency)
            that.daysTargets.forEach(t => t.innerHTML = that.calculate_days())
            that.totalPriceTargets.forEach(t => t.innerHTML = `${that.withCurrency(data.total_price, data.currency)} `)
            $(that.priceContainerTarget).removeClass('d-none')
          }
          else {
            $(that.priceContainerTarget).addClass('d-none')
          }
          // $(that.resultHolderTarget).find('.alert-success').removeClass('d-none')
          $(that.bookingButtonTarget).removeAttr('disabled')
        }
      });
    }
  }

  calculate_days() {
    if ($(this.fromTarget).val() && $(this.toTarget).val()) {
      const a = $(this.fromTarget).datepicker('getDate').getTime();
      const b = $(this.toTarget).datepicker('getDate').getTime();
      const c = 24 * 60 * 60 * 1000;
      const diffDays = Math.round(Math.abs((a - b) / (c)) + 1);
      return diffDays;
    }
  }

  withCurrency(price, currency) {
    return `${currency} ${price}`
  }

  connect() {
    var that = this;
    $(document).on("turbolinks:load", function () {
      that.initialize_datepicker();
    })
  }

  initialize_datepicker() {
    var that = this;
    var initialStartDate = this.fromTarget.value
    var initialEndDate = this.toTarget.value;
    $(this.fromTarget).datepicker({
      changeMonth: false,
      numberOfMonths: 1,
      startDate: "+1d",
      endDate: "+3y",
      beforeShowDay: function (date) {
        return that.beforeShowDay(date);
      }
    }).on('hide', function () {
      if (!!that.toTarget.value) {
        that.on_period_chosen($(that.fromTarget).datepicker("getDate"), $(that.toTarget).datepicker("getDate"));
      }
      $(that.toTarget).datepicker("option", "startDate", $(that.fromTarget).datepicker("getDate"));
    });

    $(this.toTarget).datepicker({
      changeMonth: false,
      numberOfMonths: 1,
      startDate: "+2d",
      endDate: "+3y +2d",
      beforeShowDay: function (date) {
        return that.beforeShowDay(date);
      }
    }).on('hide', function () {
      if (!!that.toTarget.value) {
        that.on_period_chosen($(that.fromTarget).datepicker("getDate"), $(that.toTarget).datepicker("getDate"));
      }
      $(that.fromTarget).datepicker("option", "endDate", $(that.toTarget).datepicker("getDate"));
    });

    var datesDefined = 0;

    if (initialStartDate !== undefined && initialStartDate !== '') {
      $(that.fromTarget).datepicker("setDate", moment(initialStartDate).toDate());
      datesDefined++;
    }

    if (initialEndDate !== undefined && initialEndDate !== '') {
      $(that.toTarget).datepicker("setDate", moment(initialEndDate).toDate());
      datesDefined++;
    }

    if (datesDefined === 2) {
      this.on_period_chosen($(this.fromTarget).datepicker("getDate"), $(this.toTarget).datepicker("getDate"));
    }
  }
}