import {Controller} from "stimulus"
import getCsrfToken from "../utils/csrf";

export default class extends Controller {
  static targets = ['invoicesList', 'invoicesForm', 'modalCloseButton', 'inputRemove']

  connect() {
    $('#modal__new-invoice').on('hidden.bs.modal', function(){
      document.dispatchEvent(new CustomEvent('pos/invoice_modal_closed'))
    })
  }

  addBookingLine() {
    let data = this.getFormData();
    this.sendRequest(event.params.url, data)
  }

  addServiceLine() {
    let data = this.getFormData();
    data.append('service_booking_kind', event.params.category);
    this.sendRequest(event.params.url, data);
  }

  removeLine() {
    let row = event.target.closest('tr')
    if (row.dataset.invoiceLineId.length){
      let input = this.inputRemoveTargets.find(l => l.dataset.invoiceLineId == row.dataset.invoiceLineId)
      input.value = '1'
      row.classList.add('hidden')
    } else {
      row.remove();
    }
  }

  refreshForm() {
    let data = this.getFormData();
    let url = this.invoicesFormTarget.action + '/refresh_form';
    this.sendRequest(url, data);
  }

  saveAsDraft(event) {
    event.preventDefault();
    let data = this.getFormData();
    let url = this.invoicesFormTarget.action;

    this.sendRequest(url, data).then(_ => {
      // close modal
      $('#modal__new-invoice').modal('hide')
      // refresh timeline
      document.dispatchEvent(new CustomEvent('pos/timeline_item_added'))
    })
  }

  sendInvoice(event) {
    event.preventDefault();
    let data = this.getFormData();
    let url = this.invoicesFormTarget.action;
    data.append('send_invoice', 1)

    this.sendRequest(url, data).then(_ => {
      // close modal
      $('#modal__new-invoice').modal('hide')
      // refresh timeline
      document.dispatchEvent(new CustomEvent('pos/timeline_item_added'))
    })
  }

  getFormData() {
    let form = this.invoicesFormTarget;
    return new FormData(form);
  }

  sendRequest(url, data) {
    let form = this.invoicesFormTarget;
    let promise = fetch(url,
      {
        method: form.method,
        headers: {'X-CSRF-Token': getCsrfToken()},
        body: data
      }
    )
    promise.then(response => response.text())
      .then(html => {
        form.parentNode.innerHTML = html;
      })
    return promise
  }

  clearForm() {
    let url = this.modalCloseButtonTarget.dataset.url;
    let form = this.invoicesFormTarget;
    fetch(url,
      {
        method: 'GET',
      }
    ).then(
      response => response.text()
    ).then(html => {
      form.parentNode.innerHTML = html;
    })
  }

  edit(event) {
    event.preventDefault();

    let url = event.target.href;
    let form = document.getElementById('new_invoice_invoice');

    fetch(url,
      {
        method: 'GET',
      }
    ).then(response => response.text())
      .then(html => {
        form.parentNode.innerHTML = html;
        $('#modal__new-invoice').modal('show')
      })
  }

  retract() {
    document.dispatchEvent(new CustomEvent('pos/timeline_item_added'));
  }

  cancel() {
    document.dispatchEvent(new CustomEvent('pos/timeline_item_added'));
  }
}
