// copied from https://github.com/basecamp/trix/blob/9fc0c9c9cdf99771ef75b9f7e16d73588bd48fb6/src/trix/config/toolbar.coffee
// - added `i` for icons
// - removed unwanted buttons
//
export function getDefaultHTML() {
    return ` 
    <div class="trix-button-row">
      <span class="trix-button-group trix-button-group--text-tools" data-trix-button-group="text-tools">
        <button type="button" class="trix-button trix-button--icon trix-button--icon-bold" data-trix-attribute="bold" data-trix-key="b" tabindex="-1">
        <i class="icon-editor-bold"></i>
        </button>
        <button type="button" class="trix-button trix-button--icon trix-button--icon-italic" data-trix-attribute="italic" data-trix-key="i" tabindex="-1">
        <i class="icon-editor-italic"></i>
        </button>
        <button type="button" class="trix-button trix-button--icon trix-button--icon-strike" data-trix-attribute="underline" tabindex="-1">
        <i class="icon-editor-underline"></i>
        </button>
      </span>
      <span class="trix-button-group trix-button-group--block-tools" data-trix-button-group="block-tools">
        <button type="button" class="trix-button trix-button--icon trix-button--icon-bullet-list" data-trix-attribute="bullet" tabindex="-1">
        <i class="icon-editor-ol"></i>
        </button>
        <button type="button" class="trix-button trix-button--icon trix-button--icon-number-list" data-trix-attribute="number" tabindex="-1">
        <i class="icon-editor-ul"></i>
        </button>
      </span>
      <span class="trix-button-group-spacer"></span>                      
    </div>
    <div class="trix-dialogs" data-trix-dialogs>
      <div class="trix-dialog trix-dialog--link" data-trix-dialog="href" data-trix-dialog-attribute="href">
        <div class="trix-dialog__link-fields">
          <input type="url" name="href" class="trix-input trix-input--dialog" placeholder="#{lang.urlPlaceholder}" aria-label="#{lang.url}" required data-trix-input>
          <div class="trix-button-group">
            <input type="button" class="trix-button trix-button--dialog" value="#{lang.link}" data-trix-method="setAttribute">
            <input type="button" class="trix-button trix-button--dialog" value="#{lang.unlink}" data-trix-method="removeAttribute">
          </div>
        </div>
      </div>
    </div>
    `;
}
