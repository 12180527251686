import {Controller} from "stimulus";

export default class extends Controller {
    static values = {
        url: String
    }


    getFile() {
        let calendar = this.listingSchedulerController.calendar;
        let startDate = calendar.getDate().toISOString();
        let params = this.filtersController.params();
        let url = `${this.urlValue}?${params}&start=${startDate}`;

        window.location.href = url;
    }

    get listingSchedulerController() {
        return this.application.controllers.find(c => c.identifier === 'listing-scheduler')
    }

    get filtersController() {
        return this.application.controllers.find(c => c.identifier === 'filters')
    }
}
