import StimulusFlatpickr from 'stimulus-flatpickr'
import flatpickr from 'flatpickr'

import {German} from "flatpickr/dist/l10n/de.js";
import {French} from "flatpickr/dist/l10n/fr.js";
import {Italian} from "flatpickr/dist/l10n/it";
import {english} from "flatpickr/dist/l10n/default.js";

import 'flatpickr/dist/themes/light.css'

import {useDispatch} from "stimulus-use";

export default class extends StimulusFlatpickr {
  static targets = [
    // Used as input, values are in `altFormat` (yyyy-mm-dd)
    "dobInput", "instance",  "holder"]

  locales = {
    de: German,
    de_formal: German,
    fr: French,
    it: Italian,
    en: english
  };


  initialize() {
    const maxDate = new Date();
    maxDate.setFullYear(maxDate.getFullYear() - 18);

    this.config = {
      locale: this.locale,
      animate: false,
      maxDate: maxDate // 18 years old as minimum
    }

    if (this.hasHolderTarget) {
      this.config.appendTo = this.holderTarget
    }
  }

  connect() {
    useDispatch(this);
    super.connect();
  }

  // In order to use flatpickr inside modals we need a way to re-initialize
  // it after the modal become visible. If you initialize when hidden it cannot
  // calculate sizes properly.
  delayedInitialization() {
    if (this.fp) {
      this.fp.destroy();
    }
    const element = this.hasFlatpickrInputTarget ? this.flatpickrInputTarget : this.flatpickrElement
    this.fp = flatpickr(element, {
      ...this.config
    })
  }

  onInputClicked(event) {
    this.delayedInitialization();
    this.onInputManuallyChanged();
    this.fp.redraw();
    this.fp.open();
  }

  onInputManuallyChanged() {
    let dob = this.dobInputTarget.value;

    this.fp.setDate(dob, true, this.altFormat);
  }

  valueUpdate(selectedDates, dateStr, instance) {
    let dobDateStr = "";
    let date = null;

    if (selectedDates.length > 0) {
      date = selectedDates[0];
      dobDateStr = instance.formatDate(date, this.altFormat);
    }

    this.dobInputTarget.value = dobDateStr;
  }

  // onReady gets triggered once the calendar is in a ready state.
  ready(dateObj, dateStr, instance) {
    let footer = document.createElement("div");
    footer.classList.add("flatpickr-footer");
    instance.calendarContainer.append(footer);

    let clearBtn = document.createElement("div");
    clearBtn.classList.add("flatpickr-clear");
    clearBtn.onclick = () => {
      instance.clear();
      instance.close();
      // this.dobInputTarget.value = null;
    }
    clearBtn.innerHTML = "Clear";
    footer.append(clearBtn);
  }

  onKeyPress(event) {
    // prevent input to be filled with visible symbols by keyboard
    if (event.charCode) event.preventDefault();
  }

  get locale() {
    if (this.locales && this.data.has("locale")) {
      return this.locales[this.data.get("locale")];
    } else {
      return this.locales['en'];
    }
  }

  get altFormat() {
    return this.data.get('altFormat');
  }

  dateToUnixTimestampSeconds(d) {
    if (d === null || d === undefined) {
      return 0;
    } else {
      return d.getTime()/1000;
    }
  }
}
