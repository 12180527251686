import { Controller } from "stimulus"
import $ from "jquery";

export default class extends Controller {
  static targets = ["filter", "url"]

  load() {
    const url = this.data.get("url");
    const filterParams = this.params();
    const lastBrandNum = document.querySelectorAll(".brand-item").length;
    fetch(`${url}?${filterParams}`)
      .then(response => response.text())
      .then(html => {
        let totalBrandsField = document.querySelector("#total_brands");
        if (totalBrandsField) totalBrandsField.remove();

        const brandsContainer = this.element.querySelector(".brands-results .tile");
        brandsContainer.insertAdjacentHTML("beforeend", html);

        totalBrandsField = document.querySelector("#total_brands");
        const loadButton = document.querySelector(".brand-load-button");
        const isLastBrandReached = totalBrandsField.value <= document.querySelectorAll(".brand-item").length;
        if (isLastBrandReached && loadButton) loadButton.remove();

        const lastBrandItem = document.querySelector(`.brand-item:nth-child(${lastBrandNum})`);
        lastBrandItem.scrollIntoView();
      })
  }

  showModal() {
    $("#modal__show-brand").modal("show");
  }

  params() {
    let filters = [];
    this.filterTargets.forEach((targetElement, i) => {
      if (!$(targetElement).hasClass("select2-hidden-accessible")) {
        filters.push({
          name: targetElement.name,
          value: targetElement.value === undefined ? targetElement.dataset["value"] : targetElement.value
        });
        return;
      }

      $(targetElement).select2("data").forEach(item => {
        filters.push({
          name: targetElement.name,
          value: item.id
        });
      })
    })

    const brandsCount = document.querySelectorAll(".brand-item").length;
    const isLastBrandReached = brandsCount % 9 > 0;
    if (!isLastBrandReached) {
      filters.push({
        name: "brand_filter[page]",
        value: brandsCount / 9 + 1
      })

      filters.push({
        name: "brand_filter[per_page]",
        value: 9
      })
    }

    return filters.map(param => `${param.name}=${param.value}`).join("&");
  }
}
