import $ from "jquery";

export default function(url, callback) {
    fetch(url)
        .then(response => response.text())
        .then(html => {
            // `id` is required to make sure same modal
            // is not appended multiple times to the DOM
            let $newEl = $(html);
            let id = $newEl.attr('id');
            let $el = $(`#${id}`);

            if ($el.length) {
                $el.remove();
            }

            $newEl.appendTo(document.body);
            $el = $(`#${id}`);
            $el.modal();

            if (id && callback) {
                callback(id);
            }

        })
}
