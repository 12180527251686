import { Controller } from "stimulus"
import "slick-carousel/slick/slick.js";

export default class extends Controller {
  static targets = ["carousel", "modelCarousel", "description"]

  connect() {
    this.element.querySelectorAll('[data-index]').forEach(button => {
      button.addEventListener('click', (event) => {
        this.element.querySelectorAll('[data-index]').forEach(btn => btn.classList.remove('active'));

        event.target.classList.add('active');

        const index = event.target.dataset.index;
        this.navigateSlide(index);
      });
    });

    var navImages = $('.nav-images').length
    if (this.hasCarouselTarget) {
      var navImages = $('.nav-images').length
      $(this.carouselTarget).slick({
          'dots': true,
          'infinite': true,
          'speed': 500,
          'autoplay': true
      });
    }

    $('.model-carousel').slick({
      'prevArrow': '<button class="slide-arrow prev-arrow"></button>',
      'nextArrow': '<button class="slide-arrow next-arrow"></button>',
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      fade: true,
      asNavFor: '.model-carousel-nav'
    });

    $('.model-carousel-nav').slick({
      slidesToShow: navImages > 5 ? navImages : 5 ,
      slidesToScroll: 1,
      asNavFor: '.model-carousel',
      dots: true,
      focusOnSelect: true,
      arrows: false
    });
  }

  disconnect() {
    $(this.carouselTarget).slick('unslick');
  }

  navigateSlide(index) {
    $(this.carouselTarget).slick('slickGoTo', index);
  }

  modelPopup(){
    this.modelCarouselTarget.classList.remove("opacity-0")
    this.modelCarouselTarget.classList.remove("z-50")
    this.modelCarouselTarget.classList.add("lightbox")
    $('#v2__navbar')[0].classList.remove('z-10')
    this.modelCarouselTarget.classList.add("overflow-hidden")
    document.querySelector("body").classList.add("overflow-hidden")
  }
  showPage(){
    this.modelCarouselTarget.classList.add("opacity-0")
    this.modelCarouselTarget.classList.add("z-50")
    $('#v2__navbar')[0].classList.add('z-10')
    this.modelCarouselTarget.classList.remove("lightbox")
    this.modelCarouselTarget.classList.remove("overflow-hidden")
    document.querySelector("body").classList.remove("overflow-hidden")
  }
  readMore(){
    if (event.currentTarget.innerText.includes("more")) {
      event.currentTarget.innerText = "Read less"
    }else{
      event.currentTarget.innerText = "Read more"
    }
    this.descriptionTarget.classList.toggle('h-20')
  }

}
