import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["translateAllLink"]

  static values = {
    translateLinkName: String,
    showOriginalLinkName: String,
    locale: String
  }

  static showOriginal;

  connect() {
    this.showOriginal = true;
    if (this.needTranslation()) {
      this.translateAllLinkTarget.classList.remove("hidden");
    }
  }

  translate() {
    if (this.everythingTranslated()) {
      this.toggle();
      return
    }

    const translatorContainers = document.querySelectorAll(".global-translator-container");
    translatorContainers.forEach(translatorContainer => {
      const translatedTextHolder = translatorContainer.querySelector(".translated-text-holder");
      const originalText = translatorContainer.getAttribute("data-global-translator-original-text");

      const translateTo = translatorContainer.getAttribute("data-global-translator-translate-to");
      const translateFrom = translatorContainer.getAttribute("data-global-translator-translate-from");
      if (translateTo === translateFrom) {
        translatedTextHolder.innerText = originalText;

        if (this.everythingTranslated()) {
          this.toggle();
        }

        return;
      }

      fetch("/deepl/translate", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          locale: this.localeValue,
          text: originalText
        }),
      })
        .then(response => response.json())
        .then(data => {
          const translatedText = data.text;
          translatedTextHolder.innerHTML = translatedText;

          if (this.everythingTranslated()) {
            this.toggle();
          }
        });
    });
  }

  toggle() {
    const translatorContainers = document.querySelectorAll(".global-translator-container");
    translatorContainers.forEach(translatorContainer => {
      const translatedTextContainer = translatorContainer.querySelector(".translated-text-container");
      const originalTextContainer = translatorContainer.querySelector(".original-text-container");
      originalTextContainer.classList.toggle("hidden");
      translatedTextContainer.classList.toggle("hidden");
    })

    if (this.showOriginal) {
      this.translateAllLinkTarget.textContent = this.showOriginalLinkNameValue;
      this.showOriginal = false;
    } else {
      this.translateAllLinkTarget.textContent = this.translateLinkNameValue;
      this.showOriginal = true;
    }
  }

  needTranslation() {
    const translatorContainers = document.querySelectorAll(".global-translator-container");
    const needTranslation = Array.prototype.map.call(translatorContainers, translator => {
      const translateTo = translator.getAttribute("data-global-translator-translate-to");
      const translateFrom = translator.getAttribute("data-global-translator-translate-from");
      return translateTo !== translateFrom;
    }).some(result => result === true)
    return needTranslation;
  }

  everythingTranslated() {
    const translations = document.querySelectorAll(".global-translator-container .translated-text-holder");
    const everythingTranslated = Array.prototype.map.call(translations, translation => {
      return translation.innerText !== '';
    }).every(result => result === true)

    return everythingTranslated;
  }
}
