import { Controller } from "stimulus";
import $ from "jquery";

import "blueimp-tmpl/js/tmpl";
import "blueimp-canvas-to-blob/js/canvas-to-blob";
import "blueimp-file-upload/js/jquery.fileupload.js";
import "blueimp-file-upload/js/jquery.fileupload-ui.js";
import "blueimp-file-upload/js/jquery.fileupload-process.js";
import "blueimp-file-upload/js/jquery.iframe-transport";
import "blueimp-file-upload/js/jquery.fileupload-validate.js";

export default class extends Controller {
  static targets = ["input", "drop", "downloadPreview", "stripeFile"];

  connect() {
    const type = this.type;
    const fileTypes = new RegExp(
      `(\.|\/)(jpe?g|png${this.includePdf ? "|pdf" : ""})$`,
      "i"
    );
    const $el = $(this.inputTarget);
    const $drop = $(this.dropTarget);

    const _stripeKey = this.data.get("stripe-key");
    const _accountId = this.data.get("account-id");
    const _this = this;

    let fp = $el
      .fileupload({
        url: "https://uploads.stripe.com/v1/files",
        uploadTemplateId: type + "-template-upload",
        downloadTemplateId: type + "-template-download",
        dataType: "json",
        autoUpload: false,
        acceptFileTypes: fileTypes,
        maxFileSize: 10000000, // 10 MB
        dropZone: $drop,
        disableImageResize: /Android(?!.*Chrome)|Opera/.test(
          window.navigator.userAgent
        ),
        previewMaxWidth: 320,
        previewMaxHeight: 200,
        previewMinWidth: 320,
        previewMinHeight: 200,
        imageMaxWidth: 8000,
        imageMaxHeight: 8000,
        singleFileUploads: true,
        beforeSend: (xhr) => {
          xhr.setRequestHeader("Authorization", "Bearer " + _stripeKey);
          xhr.setRequestHeader("Stripe-Account", _accountId);
        },
        dataFilter: (res) => {
          const response = JSON.parse(res);
          return JSON.stringify({
            files: _this.files.map((f) => {
              const file = Object.assign({}, f, response);
              file.previewURL = f?.preview?.toDataURL();

              return file;
            }),
            stripe: response,
          });
        },
      })
      .bind("fileuploadadd", (e, data) => {
        _this.files = data.files;
        _this.hideInput();
      })
      .bind("fileuploaddone", (e, data) => {
        const stripeResponse = data.result.stripe;
        if (stripeResponse) {
          _this.stripeFileTarget.value = stripeResponse.id;
          _this.callFormValidation();
        }
      })
      .bind("fileuploadfail", (e, data) => {
        if (data.jqXHR) {
          const error = data.jqXHR.responseJSON.error.message;
          _this.files = _this.files.map((f) => {
            f.error = error;
            return f;
          });
        }
      })
      .bind("fileuploadsubmit", (e, data) => {
        data.formData = { purpose: "identity_document" };
        return true;
      });
  }

  showInput() {
    this.stripeFileTarget.value = null;
    this.callFormValidation();
    $(this.dropTarget).show();
  }

  hideInput() {
    $(this.dropTarget).hide();
  }

  callFormValidation() {
    const event = new CustomEvent("validate-stripe-documents");
    window.dispatchEvent(event);
  }

  get type() {
    return this.data.get("type");
  }

  get includePdf() {
    return this.data.get("include-pdf") === "true";
  }
}
