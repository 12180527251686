import { Toggle } from "tailwindcss-stimulus-components";

export default class ButtonToggle extends Toggle {
  static targets = ["toggleable"];
  static values = { open: Boolean };

  connect() {
    super.connect();
  }

  removeClasses(event) {
    if (this.element.contains(event.target) === false && this.openValue) {
      this.openValue = false;
    }
  }
}
