import { Controller } from "@hotwired/stimulus"

export default class extends Controller { 
  static targets = ['detail']
  connect() {}

  toggleAccordian(event) {
    let active = this.detailTargets.find(d => d.open)
    if (active && active.contains(event.currentTarget)) {
      active.open = false
      event.preventDefault()
    }
    else if (!event.currentTarget.open && active) {
      active.open = false
    }
  }
}