import { Controller } from 'stimulus'
import $ from "jquery";

export default class extends Controller {
  static targets = ['dialog']

  connect() {
    // hack to solve then problem with hidden vertical scrollbar for the case
    // when page use two or more overlapped dialogs (open each other)
    $(this.dialogTarget).on('hidden.bs.modal', () => {
      $('body:has(.modal.show)').addClass('modal-open');
    })
  }
}
