import { Controller } from 'stimulus'


export default class extends Controller {
    static targets = ["perPage"];

    change() {
        const url = `${window.location.href}`;
        const perPage = this.perPageTarget[this.perPageTarget.selectedIndex].value;
        let newUrl = this.replaceUrlParam(url, 'per_page', perPage);
        newUrl = this.replaceUrlParam(newUrl, 'page', 1);

        //Turbolinks.clearCache();
        Turbolinks.visit(newUrl);
    }

    replaceUrlParam(url, paramName, paramValue) {
        if (paramValue == null) {
            paramValue = '';
        }
        var pattern = new RegExp('\\b('+paramName+'=).*?(&|#|$)');
        if (url.search(pattern)>=0) {
            return url.replace(pattern,'$1' + paramValue + '$2');
        }
        url = url.replace(/[?#]$/,'');
        return url + (url.indexOf('?')>0 ? '&' : '?') + paramName + '=' + paramValue;
    }
}