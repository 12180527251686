import { Controller } from "stimulus"

// idea of this carousel component has taken from
// https://codepen.io/gromovich/pen/LRzVBa
//
// arrow elements are hidden now

export default class extends Controller {
  static targets = ["dots", "carousel", "leftArrow", "rightArrow"];
  static items;
  static dots;
  static currentItemNum;
  static isEnabled;

  connect() {
    this.items = this.carouselTarget.querySelectorAll(".item");
    this.dots = this.dotsTarget.querySelectorAll("li");
    this.currentItemNum = 0;
    this.isEnabled = true;
    this.adjustItemsHeight();
    this.initializeArrows();
    this.initializeDotsSwitch();
  }

  changeCurrentItem(n) {
    this.currentItemNum = (n + this.items.length) % this.items.length;
  }

  nextItem(n) {
    this.hideItem("to-left");
    this.changeCurrentItem(n + 1);
    this.showItem("from-right");
  }

  previousItem(n) {
    this.hideItem("to-right");
    this.changeCurrentItem(n - 1);
    this.showItem("from-left");
  }

  goToItem(n) {
    if (n < this.currentItemNum) {
      this.hideItem("to-right");
      this.currentItemNum = n;
      this.showItem("from-left");
    } else {
      this.hideItem("to-left");
      this.currentItemNum = n;
      this.showItem("from-right");
    }
  }

  hideItem(direction) {
    const that = this

    this.isEnabled = false;
    this.items[this.currentItemNum].classList.add(direction);
    this.dots[this.currentItemNum].classList.remove("active");
    this.items[this.currentItemNum].addEventListener("animationend", e => {
      e.target.classList.remove("active", direction);
    });
  }

  showItem(direction) {
    const that = this

    this.items[this.currentItemNum].classList.add("next", direction);
    this.dots[this.currentItemNum].classList.add("active");
    this.items[this.currentItemNum].addEventListener("animationend", e => {
      e.target.classList.remove("next", direction);
      e.target.classList.add("active");
      that.isEnabled = true;
    });
  }

  adjustItemsHeight() {
    let maxHeight = 0;
    const items = this.carouselTarget.querySelectorAll(".item");
    items.forEach(item => {
      item.classList.add("active");
      let offsetHeight = item.offsetHeight;
      item.classList.remove("active");

      if (offsetHeight > maxHeight) {
        maxHeight = offsetHeight;
      }
    })

    items[0].classList.add("active");

    this.items.forEach(item => {
      item.querySelector(".tile").style.height = `${maxHeight}px`;
    });

    this.rightArrowTarget.style.top = `-${maxHeight / 2 + 12}px`
    this.leftArrowTarget.style.top = `-${maxHeight / 2 + 12}px`
  }

  initializeArrows() {
    const that = this

    if (this.leftArrowTarget) {
      this.leftArrowTarget.addEventListener("click", () => {
        if (that.isEnabled) {
          that.previousItem(that.currentItemNum);
        }
      });
    }

    if (this.rightArrowTarget) {
      this.rightArrowTarget.addEventListener("click", () => {
        if (that.isEnabled) {
          that.nextItem(that.currentItemNum);
        }
      });
    }
  }

  initializeDotsSwitch() {
    const that = this

    this.dotsTarget.addEventListener("click", e => {
      const targetNum = [].slice.call(e.target.parentNode.children).indexOf(e.target);

      if (targetNum !== that.currentItemNum && targetNum < that.dots.length) {
        that.goToItem(targetNum);
      }
    });
  }
}
