import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["field", "address1", "zip", "areaName", "cityName"];

  connect() {
    if (typeof google != "undefined") {
      this.initializeAutocomplete();
    }
  }

  initializeAutocomplete() {
    this.autocomplete = new google.maps.places.Autocomplete(this.fieldTarget);
    this.autocomplete.setFields(["address_components"]);
    this.autocomplete.addListener("place_changed", this.placeChanged.bind(this));

    this.fieldTarget.addEventListener("keydown", function (event) {
      if (event.key === "Enter") {
        event.preventDefault();
      }
    });
  }

  placeChanged() {
    let place = this.autocomplete.getPlace();
    this.setAddressResults(place);
  }

  setAddressResults(result) {
    let streetNumber = "",
      address = "",
      level1 = "",
      level2 = "",
      level3 = "",
      locality = "",
      neighborhood = "",
      sublocality = "",
      sublocalityLevel1 = "",
      // country = "",
      // countryCode = "",
      postalCode = "",
      postalTown = "";
    for (let i = 0; i < result.address_components.length; i++) {
      const addr = result.address_components[i];
      if (addr.types[0] === "country") {
        // country = addr.long_name;
        // countryCode = addr.short_name;
      } else if (addr.types[0] === "street_address") address = address + addr.long_name;
      else if (addr.types[0] === "route") address = address + addr.long_name;
      else if (addr.types[0] === "street_number") streetNumber = addr.long_name;
      else if (addr.types[0] === "administrative_area_level_1") level1 = addr.long_name;
      else if (addr.types[0] === "administrative_area_level_2") level2 = addr.short_name;
      else if (addr.types[0] === "administrative_area_level_3") level3 = addr.short_name;
      else if (addr.types[0] === "locality") locality = addr.long_name;
      else if (addr.types[0] === "postal_code") postalCode = addr.long_name;
      else if (addr.types[0] === "postal_town") postalTown = addr.long_name;
      else if (addr.types[0] === "sublocality") sublocality = addr.short_name;
      else if (addr.types[0] === "sublocality_level_1") sublocalityLevel1 = addr.short_name;
      else if (addr.types[0] === "neighborhood") neighborhood = addr.short_name;
    }

    if (this.hasAddress1Target) {
      this.address1Target.value = "" + address + " " + streetNumber;
    }

    if (this.hasZipTarget) {
      this.zipTarget.value = "" + postalCode;
    }

    if (this.hasAreaNameTarget) {
      this.areaNameTarget.value = "" + level1 || neighborhood || sublocality || sublocalityLevel1;
    }

    if (this.hasCityNameTarget) {
      this.cityNameTarget.value = "" + locality || sublocality || level3 || level2 || postalTown;
    }
  }
}
