import { Controller } from 'stimulus'

import { timelineItemAdded } from "../events/timeline";
import { getDefaultHTML } from '../utils/trix-custom-toolbar';
import {t} from '../i18n/t';

export default class extends Controller {
    static targets = ["trix", "trixArea", "trixToolbarArea"]
    connect() {
        // empty
        this.loadTrix();

        document.addEventListener("turbolinks:before-cache", () => {
            this.unMount();
        }, {once: true});
    }

    loadTrix() {
        Promise.all([import('trix/dist/trix')]).then(() => {
            let Trix = require('trix');
            Trix.config.toolbar.getDefaultHTML = getDefaultHTML;
            Trix.config.textAttributes.underline = {
                style: { textDecoration: "underline" },
                parser: element => {
                    return element.style.textDecoration === "underline"
                },
                inheritable: 1
            }
            this.mount();
        });
    }

    mount() {
        //https://github.com/basecamp/trix/blob/8dad233e008cf0f1612db4a6bb7e303dce396ada/src/trix/elements/trix_editor_element.coffee#L76-L79
        this.trixToolbarAreaTarget.innerHTML = `          
            <trix-toolbar id="trix-toolbar-1"></trix-toolbar>
        `;

        // todo i18n
        this.trixAreaTarget.innerHTML = `
            <trix-editor input="trix_result"
                 toolbar="trix-toolbar-1"
                 class="trix-content"
                 data-timeline-editor-target="trix"
                 role="textbox"
                 placeholder="${t('timeline.editor_placeholder', this.locale())}"></trix-editor>
        `;
    }

    unMount() {
        // maybe save state?
        this.trixToolbarAreaTarget.innerHTML = "";
        this.trixAreaTarget.innerHTML = "";
    }

    onSubmit(event) {
        if (this.isEmpty()) {
            event.preventDefault();
        }

        this.clearInput();
    }

    updateTimeline() {
        timelineItemAdded();
    }

    onInitialize() {
        // empty.
    }

    onChange() {
        let editor = this.trixTarget.editor;

        let range = editor.getSelectedRange();
        if (range[0] === 0 && range[1] === 0) {
            let value = editor.getDocument().toString();
            if (value.length < 2) {
                this.clearInput();
            }
        }
    }

    onFocus() {
        if(this.isEmpty()) {
            this.clearInput();
        }
    }

    onBlur() {
        let editor = this.trixTarget.editor;
        let value = editor.getDocument().toString();

        if (value.length > 2 && value.charCodeAt(0) === 32) {
            editor.setSelectedRange(0);
            editor.deleteInDirection("forward");
            editor.setSelectedRange(-1)
        }

    }

    isEmpty() {
        let editor = this.trixTarget.editor;
        let value = editor.getDocument().toString();
        return value.replace(/[\s\t\v\r\n\f]/g, '').length === 0;
    }

    clearInput() {
        let editor = this.trixTarget.editor;
        editor.loadHTML("");
        // this.clearInputWorkaround();
    }

    // clearInputWorkaround() {
    //     let editor = this.trixTarget.editor;
    //
    //     // firefox has issues with empty contenteditable, to workaround it
    //     // we add a space character, that's going to be removed whenever `onBlur`
    //     // is called and the contents are non-empty.
    //     editor.setSelectedRange(0);
    //     editor.insertString(" ");
    // }

    disconnect() {
        // empty
    }

    locale() {
        return this.trixAreaTarget.getAttribute("data-locale") || "en";
    }
}