import {Controller} from 'stimulus'
import $ from "jquery";
import "blueimp-file-upload/js/jquery.fileupload.js";
import "blueimp-file-upload/js/jquery.fileupload-ui.js";

import "blueimp-canvas-to-blob/js/canvas-to-blob";
import getCsrfToken from "../../utils/csrf";

export default class extends Controller {
  static targets = ["input", "dropzone", "description"];

  connect() {
    const name = this.data.get('name');
    const multiple = this.data.get('multiple');
    const numberOfFiles = multiple === 'true' ? 20 : 1;
    const url = this.data.get('url');
    const imageTypes = /(\.|\/)(gif|jpe?g|png|pdf)$/i;
    const $el = $(this.inputTarget);
    $el.fileupload({
      url: url,
      uploadTemplateId: name + '-template-upload',
      downloadTemplateId: name + '-template-download',
      dataType: 'json',
      replaceFileInput: false,
      dropZone: $(this.dropzoneTarget),
      maxNumberOfFiles: numberOfFiles,
      acceptFileTypes: imageTypes,
      previewCanvas: false,
      previewMaxWidth: 144,
      previewMaxHeight: 144,
      maxFileSize: 25000000 // 25 MB
    }).bind('fileuploadsubmit', function (e, data) {

      let inputs = data.context.find(':input');
      let isValid = true;

      Array.from(inputs).map(i => {
        if (!i.checkValidity()) {
          i.reportValidity()
          isValid = false
        }
      })

      if (!isValid) {
        data.context.find(':input.start').prop('disabled', false)
        return false;
      }

      data.formData = inputs.serializeArray();
    });

    if (multiple !== 'true') {
      $el.bind('fileuploadadd', function (e, data) {
        $el.find('.files').html = ''
      })

      $el.bind('fileuploaddestroy', function (e, data) {

        $el.find('.files').html = ''
      })
    }

    $.ajax({
      url: $el.fileupload('option', 'url'),
      dataType: 'json',
      context: $el[0]
    }).always(function () {
      // processing effects
    }).done(function (result) {
      try {
        $(this).fileupload('option', 'done').call(this, $.Event('done'), {result: result});
      } catch (e) {
        console.log('Error inside fileupload callback');
        console.log(e);
      }
    });
  }
  updateFile(e) {
    let previewOptions = $(e.target).closest('.preview__options');
    let input = previewOptions.find('input[name="description"]')[0];

    if (!input.checkValidity()) {
      input.reportValidity();
      return false;
    }

    let description = input.value;
    let data = new FormData;
    data.append('description', description);

    fetch(e.params.url, {
      method: e.params.method,
      headers: {'X-CSRF-Token': getCsrfToken()},
      body: data
    }).then(response => response.json())
      .then(data => {
          previewOptions.find('div.description').text(data.files[0].description);
        }
      );
  }
} 