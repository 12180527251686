import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["features", "title", 'arrowDown', 'arrow', "showTitle", "moreFeatures", "feature"]
  static values = {more: String, less: String}

  showIncludeFees() {
   
    this.arrowDownTarget.classList.toggle('text-green-brand')
    this.arrowTarget.classList.toggle('rotate-180')
  }

  showMoreFeatures(){
    var feat = this.showTitleTarget
    if (feat.innerHTML.includes("less") || feat.innerHTML.includes('Weniger') || feat.innerHTML.includes('moins')) {
      feat.innerHTML = this.moreValue
    }
    else {
      feat.innerHTML = this.lessValue
    }
    this.featureTargets.forEach(feature => feature.classList.toggle("hidden"))
  }
}
