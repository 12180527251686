import { Controller } from 'stimulus'

export default class extends Controller {

    connect() {
        // Issues related to turbolinks, defer, etc.
        //
        // https://github.com/ankane/chartkick/issues/536#issuecomment-720229441
        // https://github.com/ankane/chartkick/issues/551
        // https://github.com/ankane/chartkick/issues/480
        //
        if ('Chartkick' in window) return;

        Promise.all([import('chartkick'), import('chart.js')]).then(([Chartkick]) => {
            // will be part of Chartkick.js 4.0
            window.dispatchEvent(new Event("chartkick:load"));

            // will be part of Chartkick.js 4.0
            Chartkick.destroyAll = function() {
                for (var id in Chartkick.charts) {
                    Chartkick.charts[id].destroy();
                    delete Chartkick.charts[id];
                }
            }

            // may be part of Chartkick.js 4.0 or may leave it to apps to add
            document.addEventListener("turbolinks:before-render", function() {
                Chartkick.destroyAll();
            }, {once: true});
        });
    }
}