import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    unsetUrl: String
  }

  connect() {
  }

  change(event) {
    const value = event.target.value
    const url = value || this.unsetUrlValue

    if (url) {
      fetch(`${url}`, {
        method: 'GET',
        headers: {'Accept': 'text/html'}
      })
        .then(response => {
          if (response.redirected) {
            window.location.href = response.url;
          }
        })
        .catch(error => console.error('Error:', error));
    }
  }
}
