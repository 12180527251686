import { Controller} from "stimulus"
import Autonumeric from "autonumeric";

export default class extends Controller {
  static autonumericInputs

  connect() {
    this.initAutonumericInputs();
    this.toggleEmailNotification();
    this.initFootfallSwitcher();
  }

  initFootfallSwitcher() {
    const footfallPerWeek = document.querySelector("[name='building_building[footfall_per_week]']");
    const footfallPerYear = document.querySelector("[name='building_building[footfall_per_year]']");
    const switcher = document.querySelector("[name='building_building[footfall_period]']");

    switcher.addEventListener("change", () => {
      switch (switcher.value) {
        case "week":
          footfallPerYear.classList.add("is-hidden");
          footfallPerWeek.classList.remove("is-hidden");
          break;
        case "year":
          footfallPerWeek.classList.add("is-hidden");
          footfallPerYear.classList.remove("is-hidden");
          break;
      }
    });
  }

  initAutonumericInputs() {
    const currency = this.element.dataset.currency;
    const decimalMark = this.element.dataset.decimalMark;
    const thousandsSeparator = this.element.dataset.thousandsSeparator;

    this.autonumericInputs = new Autonumeric.multiple(".currency-symbol", {
      digitGroupSeparator: thousandsSeparator,
      decimalCharacter: decimalMark,
      currencySymbolPlacement: Autonumeric.options.currencySymbolPlacement.prefix,
      currencySymbol: `${currency} `,
      minimumValue: 0
    });

    const currencySwitcher = document.getElementById("building_building_currency");
    currencySwitcher.addEventListener("change", () => {
      const newCurrency = currencySwitcher.value.toUpperCase();
      this.autonumericInputs.forEach(input => input.update({ currencySymbol: `${newCurrency} ` }));
    })
  }

  toggleEmailNotification() {
    const emailInput = document.getElementById("building_building_email");
    if (emailInput === null) {
      return
    }
    const isNotificationEnabled = document.getElementById("building_building_enable_notification_true").checked;
    const emailAddressField = emailInput.parentNode;

    if (isNotificationEnabled) {
      emailAddressField.style.display = "grid";
    } else {
      emailAddressField.style.display = "none";
      emailInput.value = null;
    }
  }
}