export function totalRent ({shopSize, shopTypeFactor, rentalLength, rentPerSqm}) {
  return Math.round(shopSize * rentalLength * rentPerSqm * shopTypeFactor)
}

export function totalStaff({staffCount, rentalLength, ratePerStaff}) {
  return staffCount * rentalLength * ratePerStaff
}

export function totalFurniture({shopSize, rentalLength, furnitureCost}) {
  return shopSize * rentalLength * furnitureCost; 
}

export function totalMarketingAndPaymentSystems ({marketingCost, paymentSystemsCost, shopConceptFactor })  {
  return (marketingCost * shopConceptFactor) + paymentSystemsCost
}

export function totalLegal ({legalCost, shopTypeFactor}) {
  return Math.round(shopTypeFactor * legalCost);
}

export function total(costs) {
  return costs.reduce((sum, item) => sum + item);
}

export function minimalRevenue ({totalCost, profitMarginExpected}) {
  return Math.round(totalCost / (1-(profitMarginExpected/100))) 
}

export function totalProfit ({minimalRevenue, totalCost}) {
  return minimalRevenue - totalCost;
}

export function totalOther ({adminOther, cost}) {
  return Math.round(cost * (adminOther / 100));
}

export function percentage({part, total}) {
  return (part / total * 100).toFixed(1);
}
