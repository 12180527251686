import  PaymentController from "./payment_controller.js"
import "parsleyjs"

export default class extends PaymentController { 

  static targets = ["publishableKey", "stripeForm", "saveCard", "submit", "stripeError", "icon", "newCardContainer", "newCardInput", "name", "paymentMethodContainer", "cardIconinnerCircle", "cardIconOuterCircle"]

  connect() {
    this.stripeInit()
    this.errorStripeHandle()
    this.inputFocusHandler()
  }

  // Initialized and created stripe elements to mount in the form with the basic styling
  stripeInit(){
    this.stripe = Stripe(this.publishableKeyTarget.value)
    this.elements = this.stripe.elements({
      fonts: [
        {
          cssSrc: 'https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap',
        },
      ],
      locale: 'auto',
    });

    this.cardNumber = this.elements.create('cardNumber', {
      style: this.elementStyles,
      classes: this.elementClasses,
    });
    this.cardNumber.mount('#payment-card-number');
  
    this.cardExpiry = this.elements.create('cardExpiry', {
      style: this.elementStyles,
      classes: this.elementClasses,
    });
    this.cardExpiry.mount('#payment-card-expiry');
  
    this.cardCvc = this.elements.create('cardCvc', {
      style: this.elementStyles,
      classes: this.elementClasses,
    });
    
    this.cardCvc.mount('#payment-card-cvc');
  }

  // to show the error messages below the stripe elements (cardNumber, cardExpiry, cardCvc)
  errorStripeHandle(){
    [this.cardNumber, this.cardExpiry, this.cardCvc].forEach(function(element, idx) {
      element.on('change', function(event) {
        var element =  document.querySelector(`#error-${event.elementType}`);
        if (event.error) {
          element.innerText = event.error.message;
        } else{
          element.innerText = ""
        }
      });
    });
  }

  // to add the border and made the input field checked on the selected option and remove border from others
  inputFocusHandler(){
    var that = this;
    [this.cardNumber, this.cardExpiry, this.cardCvc, this.nameTarget].forEach(function(element, idx) {
      element.addEventListener("focus", function(event) {
        that.selectNewCard()
      });
    });
  }

  selectMethod(){
    this.selectNewCard()
  }

  // adding background border on the custom checkbox on acceot terms
  acceptTerms(){
    this.iconTarget.classList.toggle('bg-green-brand')
  }

  // submitting the payment form after validating the form
  stripeSubmit(event){
    event.preventDefault();
    if (this.validate ){
      Rails.disableElement(this.submitTarget)
      this.confirmCardPayment()
    }
  }

  // to authenticate the card and checks the of the stripe itself
  confirmCardPayment(){
    var that = this
    this.paymentIntentClientSecret = this.stripeFormTarget.dataset.paymentIntentClientSecret
    this.stripe.confirmCardPayment(this.paymentIntentClientSecret, this.paymentPayload).then(function(result) {
      // Handle result.error or result.paymentIntent
      if (result.error) {
        that.displayStripeError(result.error);
      } else {
        that.pay(result.paymentIntent.id);
      }
    });
  }

  // to pay the amount due using stripe API
  pay(paymentIntentId) {
    var that = this
    this.paymentForm = this.stripeFormTarget
    this.stripUrl = this.paymentForm.dataset.payUrl

    fetch(this.stripUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': $( 'meta[name="csrf-token"]' ).attr( 'content' ),
      },
      body: JSON.stringify({
        payment_intent_id: paymentIntentId,
      })
    }).then(function (result) {
      result.json().then(function (json) {
        that.handleServerResponse(json);
      })
    });
  }

  // getting the response on API hit and handling the response in case of error and success
  handleServerResponse(response) {
    var that = this
    if (response.error) {
     this.displayStripeError(response.error);
    } else if (response.requires_action) {
      // Use Stripe.js to handle required card action
      this.stripe.handleCardAction(
        response.payment_intent_client_secret

      ).then(that.handleStripeJsResult);

    } else if (response.requires_source_action) {
      // Payment Intent was created with existing payment method,
      // and now we need to confirmCardPayment.
      this.stripe.confirmCardPayment(
        response.payment_intent_client_secret, {
          // setup_future_usage: 'off_session'
        }
      ).then(function(result) {
        if (result.error) {
          this.displayStripeError(result.error);
        } 
      });
    } 
    else {
      $(this.paymentForm).trigger('submit.rails');
    }
  }

  handleStripeJsResult(result) {
    var that =  this
    if (result.error) {
      this.displayStripeError(result.error);
    } else {
      // The card action has been handled
      // The PaymentIntent can be confirmed again on the server
      fetch(this.stripUrl, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': $('meta[name="csrf-token"]').attr( 'content' ),
        },
        body: JSON.stringify({ payment_intent_id: result.paymentIntent.id })

      }).then(function(confirmResult) {
        return confirmResult.json();
      }).then(that.handleServerResponse);
    }
  }
  
  // to display the error below each element
  displayStripeError(error) {
    Rails.enableElement(this.submitTarget)
    this.stripeErrorTarget.classList.remove('hidden');
    this.stripeErrorTarget.innerHTML = error.message;
  }

  // addig stripe validations on the form
  get validate(){
    $(this.stripeFormTarget).parsley().reset()
    if(this.selectedPaymentCard == "false"){
      return $(this.stripeFormTarget).parsley().validate();
    }else{
      return $(this.stripeFormTarget).parsley().validate({group: "term"})
    }
  }

  // to get if the card being used is saved one or a new card
  get paymentPayload(){
    this.saveCard = this.saveCardTarget.checked
    var setupFutureUsage = this.saveCard ? 'off_session' : 'on_session';

    if(this.selectedPaymentCard == "false"){
      return {
        payment_method: {
          card: this.cardNumber,
        },
        setup_future_usage: setupFutureUsage
      }
    }else{
      return {
        payment_method: this.selectedPaymentCard
      }
    }
  }

  // styling the elements
  get elementStyles() {
    return {  
      theme: 'stripe',    
      base: {
        fontFamily: 'Manrope, sans-serif',
        color: '#393F3E',
        fontSize: '12px',
        fontSmoothing: 'antialiased',
        fontWeight:'700',
        lineHeight: "46px",

        ':focus': {
          color: '#393F3E',
        },
  
        '::placeholder': {
          color: '#D8D8D4',
        },
  
        ':focus::placeholder': {
          color: '#D8D8D4',
        },
      },
      invalid: {
        color: '#EC1B40',
        borderColor: "#EC1B40",
        ':focus': {
          color: '#EC1B40',
        }
      },
    };
  }

  get elementClasses(){
    return {
      focus: 'focus',
      empty: 'empty',
      invalid: 'invalid',
    };
  
  }

}