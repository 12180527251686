import { Controller } from "stimulus";
import $ from "jquery";
import _ from "underscore";

export default class extends Controller {
  static targets = ["filter"];

  connect() {
    this.element[this.identifier] = this;
    this.initializeSearchField();
    this.initializeMobileSearchField();
  }

  initializeSearchField() {
    const expander = document.querySelector(".term-expander");
    const searchInput = document.querySelector("input[name='search[term]']");
    const clearBtn = document.querySelector(".term-filter-clear-btn");
    if (!expander) return;

    setInterval(() => {
      const isExpanded = expander.classList.contains("expanded");
      if (isExpanded) return;

      const searchInputWidth = searchInput.getBoundingClientRect().width;
      if (searchInputWidth > 172 && searchInputWidth < 181) return;
      if (searchInputWidth > 180) {
        expander.classList.add("is-hidden");

        if (!!searchInput.value) clearBtn.classList.remove("is-hidden");
      } else {
        expander.classList.remove("is-hidden");
        clearBtn.classList.add("is-hidden");
      }
    }, 300);

    expander.addEventListener("click", () => {
      const filterFields = document.querySelector(".header__dropdowns");
      const isExpanded = expander.classList.contains("expanded");

      if (isExpanded) {
        expander.classList.remove("expanded");
        filterFields.classList.remove("is-hidden");
        clearBtn.classList.add("is-hidden");
      } else {
        expander.classList.add("expanded");
        filterFields.classList.add("is-hidden");

        if (!!searchInput.value) clearBtn.classList.remove("is-hidden");
      }
    });

    searchInput.addEventListener("click", (e) => {
      const isHidden = expander.classList.contains("is-hidden");
      if (isHidden) return;

      e.preventDefault();

      const isExpanded = expander.classList.contains("expanded");
      if (!isExpanded) expander.dispatchEvent(new Event("click"));
    });
  }

  initializeMobileSearchField() {
    const searchInput = document.querySelector("input[name='search[term]'][data-mobile-filter='true']");
    if (!searchInput) return;

    if (searchInput.value) {
      searchInput.classList.add('border-green-brand');
    }

    searchInput.addEventListener("input", _.debounce(() => {
      if (searchInput.value) {
        searchInput.classList.add('border-green-brand');
      } else {
        searchInput.classList.remove('border-green-brand');
      }
    }, 200))
  }

  archive(event) {
    const currentArchiveFlag = event.currentTarget.dataset["value"];
    event.currentTarget.dataset["value"] = currentArchiveFlag === "true" ? "false" : "true";
  }

  filter() {
    let url = `${window.location.pathname}?${this.params()}`;

    // do not overwrite perPage parameter
    if (this.perPage) {
      url = `${url}&${this.perPage}`;
    }

    Turbolinks.clearCache();
    Turbolinks.visit(url);
  }

  onEnter(event) {
    if (event.keyCode === 13) {
      this.filter();
    }
  }

  onKeyUp(event) {
    const clearBtn = document.querySelector(".term-filter-clear-btn");
    if (clearBtn === undefined) return;

    if (event.target.value.length > 0) {
      clearBtn.classList.remove("is-hidden");
      clearBtn.style.display = "block";
    } else {
      clearBtn.classList.add("is-hidden");
      clearBtn.style.display = "none";
    }
  }

  clearTerm() {
    const termFilterIndex = this.filterTargets.findIndex((element) => {
      return element.classList.contains("term-filter");
    });
    const url = `${window.location.pathname}?${this.params([termFilterIndex])}`;

    Turbolinks.clearCache();
    Turbolinks.visit(url);
  }

  params(skip) {
    if (skip === undefined) skip = [];

    // Workaround for multiple selection with Select2
    // https://select2.org/programmatic-control/retrieving-selections
    let filters = [];
    this.filterTargets.forEach((t, i) => {
      if (skip.includes(i)) return;

      if (this.desktopWidth) {
        if ($(window).width() < this.desktopWidth && !t.dataset.mobileFilter) {
          return;
        }
        if ($(window).width() >= this.desktopWidth && t.dataset.mobileFilter) {
          return;
        }
      }

      console.log(t.id);

      // https://select2.org/programmatic-control/methods#checking-if-the-plugin-is-initialized
      if (!$(t).hasClass("select2-hidden-accessible")) {
        filters.push({
          name: t.name,
          value: t.value === undefined ? t.dataset["value"] : t.value,
        });
        return;
      }

      $(t)
        .select2("data")
        .forEach((item) => {
          filters.push({
            name: t.name,
            value: item.id,
          });
          return;
        });
    });

    return filters.map((t) => `${t.name}=${t.value}`).join("&");
  }

  get desktopWidth() {
    if (!this.data.get("desktopWidth")) {
      return;
    }

    return parseInt(this.data.get("desktopWidth"));
  }

  get perPageParamName() {
    return "per_page";
  }

  get perPage() {
    const url = window.location.href;
    var pattern = new RegExp("\\b(" + this.perPageParamName + "=).*?(&|#|$)");
    let match = url.match(pattern);
    if (match !== null) {
      // returns 'per_page=xx'
      return match[0];
    } else {
      return "";
    }
  }
}
