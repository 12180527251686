import {Controller} from "stimulus"

export default class extends Controller {
  static targets = ["container"]
  static values = { fetchUrl: String }

  updateAlert() {
    fetch(this.fetchUrlValue)
      .then(response => response.text())
      .then(text => {
        this.containerTarget.innerHTML = text;
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }
}