import { Controller } from "stimulus"
import $ from "jquery";

export default class extends Controller {
  connect() {
    const listingsSelect = $("#listing_ids");
    const locationTypesSelect = $("#location_types");
    locationTypesSelect.on("select2:select select2:unselect", () => {
      const locationTypes = locationTypesSelect.val();
      const listingsSelectDataAttributes = listingsSelect.data("pos");
      const listingsSelectURL = new URL(listingsSelectDataAttributes.ajax.url);

      listingsSelectURL.searchParams.delete("filters[location_type]");
      locationTypes.forEach(locationType => {
        listingsSelectURL.searchParams.append("filters[location_type]", locationType)
      })

      listingsSelectDataAttributes.ajax.url = this.urlToString(listingsSelectURL);
      listingsSelectDataAttributes.ajax.processResults = (data, page) => {
        return {
          results: $.map(data, function (x, i) {
            return { id: x.id, text: x.text }
          })
        }
      };
      listingsSelect.val("");
      listingsSelect.select2(listingsSelectDataAttributes);
    })
  }

  urlToString(url) {
    const typeParam = `type=${url.searchParams.get("type")}`;
    let locationTypeParams = url.searchParams.getAll("filters[location_type]")
    locationTypeParams = locationTypeParams.map(locationType => { return `filters[location_type][]=${locationType}` });

    let searchParams = [typeParam];
    locationTypeParams.forEach(locationTypeParam => { searchParams.push(locationTypeParam) });

    return `${url.origin}${url.pathname}?${searchParams.join("&")}`
  }
}
