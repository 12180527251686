import { Controller } from "stimulus"

export default class extends Controller {
  static targets =[
    "originalTextContainer",
    "originalTextHolder",
    "translatedTextContainer",
    "translatedTextHolder"]

  static values = {
    translateTo: String,
    translateFrom: String,
    originalText: String
  }

  connect() {
    if (this.translateToValue !== this.translateFromValue) {
      const translateLink = this.originalTextContainerTarget.querySelector(".translate-btn");
      translateLink.classList.remove("hidden");
    }
  }

  translate() {
    if (this.translateToValue === this.translateFromValue) {
      this.toggleText();
      return;
    }

    fetch("/deepl/translate", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        locale: this.translateToValue,
        text: this.originalTextValue
      }),
    })
    .then(response => response.json())
    .then(data => {
      this.translatedTextHolderTarget.innerText = data.text
      this.toggleText();
    })
  }

  toggleText() {
    this.translatedTextContainerTarget.classList.toggle("hidden")
    this.originalTextContainerTarget.classList.toggle("hidden")
  }
}
