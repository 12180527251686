export function squareRadioButton({ element, options, inactiveClass, activeClass }) {
  const inactive = inactiveClass || "btn-secondary";
  const active = activeClass || "btn-primary";
  const currentValue = element.getAttribute("data-value");
  let buttons = [];

  options.forEach(option =>{
    const buttonStyleClass = currentValue === option.value ? `btn ${active}` : `btn ${inactive}`;
    const buttonOption = document.createElement("button");
    buttonOption.setAttribute("type", "button");
    buttonOption.setAttribute("data-value", option.value);
    buttonOption.className = `square-radio-button-option ${buttonStyleClass}`;
    buttonOption.innerText = option.label;
    buttonOption.onclick = (event) => {
      buttons.forEach(button => {
        button.className = `square-radio-button-option btn ${inactive}`;
      });

      const thisButton = event.target;
      thisButton.className = `square-radio-button-option btn ${active}`;
      element.setAttribute("data-value", thisButton.getAttribute("data-value"));
      dispatchEvent(element, "change");
    };

    buttons.push(buttonOption);
    element.appendChild(buttonOption);
  });

  addEvent(element, "change", event => {});
}

export function initializeRadioButtons() {
  const optionButtons = document.getElementsByClassName("square-radio-button-option");
  while (optionButtons.length > 0) {
    optionButtons[0].remove();
  }

  document.getElementsByClassName("square-radio-buttons").forEach(element => {
    const options = JSON.parse(element.getAttribute("data-options"));
    squareRadioButton({element, options});
  });
}

function addEvent(element, type, handler) {
  element.attachEvent ? element.attachEvent("on" + type, handler) : element.addEventListener(type, handler);
}

function dispatchEvent(element, eventName) {
  const event = new Event(eventName, {
    bubbles: true,
    cancelable: true,
  });
  element.dispatchEvent(event);
}
