import { Controller } from "stimulus"
import baguetteBox from 'baguettebox.js';

export default class extends Controller {
  connect() {
    baguetteBox.run(this.data.get('selector'))
  }

  disconnect() {
    // https://github.com/feimosi/baguetteBox.js/issues/227#issuecomment-765697526
    if (document.getElementById('baguetteBox-overlay')) {
      baguetteBox.destroy()
    }
  }
}
