import { Controller } from 'stimulus'
import $ from "jquery";

export default class extends Controller {
  static targets = ["filter"]

  connect() {
    this.filter =  this.filter.bind(this)
    document.addEventListener('pos/places_changed', this.filter, false);
  }

  filter() {
    let url = `${window.location.pathname}?search[term]=${this.filterTarget.value}`;
    Turbolinks.clearCache();
    Turbolinks.visit(url);
  }

  onEnter(event) {
    if (event.keyCode === 13) {
      this.filter();
    }
  }
}