import {Controller} from 'stimulus'

//
// Hide/show input based on selected option
//

export default class extends Controller {
    static targets = ["select", "input", "inputWrapper"];

    static values = {
        showOption: String
    }

    connect() {
        this.toggle()
    }

    toggle(event) {
        if (this.selectTarget.value == this.showOptionValue) {
            this.inputWrapperTarget.classList.remove('hidden')
            this.inputTarget.disabled = false
        } else {
            this.inputWrapperTarget.classList.add('hidden')
            this.inputTarget.disabled = true
        }
    }
}