import { Controller } from 'stimulus'

//
// Provides kind-of controlled tabs;
// there are multiple tabs but a single one can be toggled
// at a time (in the UI it's a radio group controlling which
// tabs are visible.
//
export default class extends Controller {
    static targets = ["toggled"];

    toggle(event) {
        const toggleClass = this.data.get('toggleClass')
        const selectedIndex = event.currentTarget.dataset.index;

        if (selectedIndex === undefined) {
            return;
        }

        this.toggledTargets.forEach(function (item, i) {
            if (Number(i) === Number(selectedIndex)) {
                item.classList.add(toggleClass);
            } else {
                item.classList.remove(toggleClass);
            }
        });
    }
}