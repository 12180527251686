import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["successModalContainer", "saveRecord", "initialModalClose", "buttonValue"];
  static values = {
    backdropColor: { type: String, default: 'rgba(0, 0, 0, 0.8)' },
    restoreScroll: { type: Boolean, default: true }
  }

  openSuccessModal() {
    var that = this
    if ($("#save-form").parsley().validate()){
      that.successModalContainerTarget.classList.remove(that.toggleClass);
      that.initialModalCloseTarget.click()
      $(that.saveRecordTarget).trigger('submit.rails');
      if (!that.data.get("disable-backdrop")) {
        document.body.insertAdjacentHTML('beforeend', that.backgroundHtml);
        that.background = document.querySelector(`#${that.backgroundId}`);
      }
    }
  }

  skipValidation() {
    this.buttonValueTarget.value = 'sign-in'
    $(this.saveRecordTarget).parsley().destroy();
    $(this.saveRecordTarget).trigger('submit.rails');
  }

  removeBackground() {
    if (this.background) { this.background.remove() }
  }

  _backgroundHTML() {
    return `<div id="${this.backgroundId}" class="fixed top-0 left-0 w-full h-full" style="background-color: ${this.backdropColorValue}; z-index: 9998;"></div>`;
  }

  removeSticky(){
    $('.search-section').removeClass('sticky')
  }

  addSticky(){
    $('.search-section').addClass('sticky')
  }

  connect() {
    this.backgroundId = 'modal-background';
    this.backgroundHtml = this._backgroundHTML();
    this.toggleClass = 'hidden';
  }
}

