import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['detail','plusIcon','minusIcon']
  
  toggleCards(event) {
    this.detailTarget.classList.toggle("hidden")
    this.plusIconTarget.classList.toggle("hidden")
    this.minusIconTarget.classList.toggle("hidden")
  }
}