import { Controller } from "stimulus"
import ScrollSpy from "bootstrap5/js/dist/scrollspy";

export default class extends Controller {
  static targets = [
    "container",
  ]

  connect() {
    const navigationId = this.data.get('navigation')

    if (document.getElementById(navigationId) !== null) {
      this.scrollSpy = new ScrollSpy(
        this.containerTarget, {
          target: `#${navigationId}`
        }
      )
    }
  }

  disconnect() {
    if (this.scrollSpy) {
      this.scrollSpy.dispose()
    }
  }
}
