import { event } from "jquery"
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['detail', 'arrowDown','arrow', 'loadMore', 'showTemplate']
  static values = {url: String, moreBrandsUrl: String, hasMoreBrands: Boolean, brandsContainerId: String}

  connect(){
    this.next_brand_page = 1
    this.hasMoreBrands = this.hasMoreBrandsValue;
  }

  toggleBorder(event){
    let label = $(`label[for=${event.currentTarget.id}]`)[1]
    label.classList.toggle('border-green-brand')
    label.classList.toggle('border-gray-800')
    if(label.dataset.urlValue){
      let url  = label.dataset.urlValue
      $.ajax({url})
    }
  }

  toggleIncludeFees() {
    this.arrowDownTarget.classList.toggle('text-green-brand')
    this.arrowDownTarget.classList.toggle('text-gray-600')
    this.arrowTarget.classList.toggle('rotate-180')
  }

  toggleAccordian(event) {
    let active = this.detailTargets.find(d => d.open)
    if (active && active.contains(event.currentTarget)) {
      active.open = false
      event.preventDefault()
    }
    else if (!event.currentTarget.open && active) {
      active.open = false
    }
  }
  
  showMoreTemplates(){
    if (this.hasMoreBrands) {
      const url = this.moreBrandsUrlValue + `?page=${this.next_brand_page}`;

      fetch(url)
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response error');
          } else {
            this.next_brand_page += 1
          }
          return response.text();
        })
        .then(html => {
          const parser = new DOMParser();
          const doc = parser.parseFromString(html, 'text/html');
          const contentElements = Array.from(doc.body.children);
          if (contentElements.length < 8) {
            this.loadMoreTarget.remove();
          }

          const targetElement = document.getElementById(this.brandsContainerIdValue);
          if (targetElement) {
            contentElements.forEach(child => {
              targetElement.appendChild(child.cloneNode(true));
            });
          } else {
            console.error(`Element not found.`);
          }
        })
        .catch(error => {
          console.error('Error loading HTML:', error);
        });
    }
  }
}
