import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
  static targets = ['modalContent', 'number', 'openModal', 'totalPageCount', 'container']
  static values = { url: String }
  connect() {
    this.current = 0;
    this.containerTarget.click()
    this.myState = {
      pdf: null,
      currentPage: 1,
      zoom: 2.5
    }
    this.render()
  }

  fullView(){
    this.openModalTarget.classList.toggle('fullScreen')
  }
  render() {
    var that= this
    pdfjsLib.getDocument(this.urlValue).then((pdf) => {
      that.myState.pdf = pdf;
      that.myState.pdf.getPage(that.myState.currentPage).then((page) => {
        this.totalPageCountTarget.innerHTML =  that.myState.pdf._pdfInfo.numPages
        var canvas = document.getElementById("pdf_renderer");
        
        var ctx = canvas.getContext('2d');
  
        var viewport = page.getViewport(that.myState.zoom);
  
        canvas.width = viewport.width;
        canvas.height = viewport.height;
  
        page.render({
          canvasContext: ctx,
          viewport: viewport
        });
      });
    });
  }

  nextPage(){
    if(this.myState.pdf == null || this.myState.currentPage >= this.myState.pdf._pdfInfo.numPages) 
    return;
          
    this.myState.currentPage += 1;
    this.numberTarget.value = this.myState.currentPage;
    this.render();
  }

  prevPage(){
    if(this.myState.pdf == null || this.myState.currentPage == 1) 
    return;
          
    this.myState.currentPage -= 1;
    this.numberTarget.value = this.myState.currentPage;
    this.render();
  }

  closeWithMinimize(){
    this.openModalTarget.classList.remove('fullScreen')
  }

  updateContent() {
    this.modalContentTargets.forEach(content => {
      if (content.classList.contains('active')) {
        content.classList.remove('active')
      }
    })
    this.modalContentTargets[this.current].classList.add('active')
  }
}