import rater from "rater-js"

export default function initializeRaters({ callback }) {
  document.getElementsByClassName("star-rater").forEach(element => {
    const readOnly = element.getAttribute("read-only") || false;
    const maxStars = element.getAttribute("max-stars") || 5;
    const starSize = element.getAttribute("star-size") || 30;
    const step = element.getAttribute("step") || 1;
    const ratingComponent = rater({
      element: element,
      max: maxStars,
      starSize: starSize,
      readOnly: readOnly,
      step: step,
      rateCallback: (rating, done) => {
        ratingComponent.setRating(rating);
        if (callback) callback(ratingComponent);
        done();
      }
    });
  });
}
