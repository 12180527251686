import { Controller } from 'stimulus'
import $ from "jquery";

import { uploadDone } from "../events/upload";

export default class extends Controller {
  static targets = ["input", "preview", "uploadLink", "updateLinks"];

  connect() {
    const url = this.data.get('url');
    const imageTypes = /(\.|\/)(gif|jpe?g|png)$/i;
    const formDataMethod = JSON.parse(this.data.get('form-data'));

    const $el = $(this.inputTarget);
    const $preview = $(this.previewTarget);
    const $uploadLink = $(this.uploadLinkTarget);
    const $updateLinks = $(this.updateLinksTarget);

    $el.fileupload({
      url: url,
      uploadTemplateId: null,
      downloadTemplateId: null,
      dataType: 'json',
      autoUpload: true,
      maxNumberOfFiles: 1,
      acceptFileTypes: imageTypes,
      maxFileSize: 25000000 // 25 MB
    }).bind('fileuploaddone', function(e, data) {
      const fileUrl = data.response().result.files[0].url;
      $preview.css("background", `url(${fileUrl})`).removeClass("empty");
      $uploadLink.hide();
      $updateLinks.show();
      uploadDone();
    }).bind('fileuploadsubmit', function(e, data) {
      data.formData = formDataMethod;
    });
  }

  removeUploadedImage() {
    $.ajax({
      url: this.data.get('url'),
      type: 'PUT',
      dataType: 'json',
      data: { avatar: null }
    }).done(() => {
      $(this.previewTarget).css("background", "").addClass("empty");
      $(this.uploadLinkTarget).show();
      $(this.updateLinksTarget).hide();
    });
  }

  disconnect() {
  }

  unmount() {
  }
}