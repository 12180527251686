import { Controller } from 'stimulus'
import initializeRaters from "../components/star-rater";
import { initializeRadioButtons } from "../components/square-radio-button";
import { fadeOut } from "../components/fade-effects";
import getCsrfToken from "../utils/csrf";

export default class extends Controller {
  connect() {
    initializeRaters({ callback: this.onRaterClick });
    initializeRadioButtons();
  }

  prepareFeedbackData(type) {
    let feedbackData = {
      public_review: document.getElementById("public_review").value,
      private_review: document.getElementById("private_review").value,
      comment: document.getElementById("comment").value
    };

    if (type === "landlord") {
      feedbackData.brand_score = this.fetchScore("brand_score");
      feedbackData.brand_fit_score = this.fetchScore("brand_fit_score");
      feedbackData.concept_score = this.fetchScore("concept_score");
      feedbackData.popup_score = this.fetchScore("popup_score");
    } else if (type === "tenant") {
      feedbackData.landlord_score = this.fetchScore("landlord_score");
      feedbackData.listing_accurate_score = this.fetchScore("listing_accurate_score");
      feedbackData.location_score = this.fetchScore("location_score");
      feedbackData.space_score = this.fetchScore("space_score");
      feedbackData.space_fit_score = this.fetchScore("space_fit_score");
      feedbackData.good_footfall = this.fetchAnswer("good_footfall");
      feedbackData.good_revenue = this.fetchAnswer("good_revenue");
      feedbackData.good_price = this.fetchAnswer("good_price");
      feedbackData.success_popup = this.fetchAnswer("success_popup");
    }

    return feedbackData;
  }

  fetchScore(elementId) {
    return document.getElementById(elementId).getAttribute("data-rating");
  }

  fetchAnswer(elementId) {
    return document.getElementById(elementId).getAttribute("data-value");
  }

  showNotification(message, kind) {
    const errorsContainer = document.getElementById("feedback_errors");
    const notifier = document.createElement("div");
    let notifierClass = "alert";
    switch (kind) {
      case "warning":
        notifierClass = "alert alert-warning";
        break;
      case "success":
        notifierClass = "alert alert-success";
        break;
    }

    notifier.className = notifierClass;
    notifier.innerHTML = `<button class="close" data-dismiss="alert">×</button>${message}`;
    errorsContainer.appendChild(notifier);
    fadeOut({ element: notifier, remove: true });
  }

  onRaterClick() {
    let votedRates = 0;
    let totalScore = 0.0;
    document.getElementsByClassName("star-rater").forEach(rater => {
      const score = rater.getAttribute("data-rating");
      if (!score) return;

      votedRates += 1;
      totalScore += parseInt(score);
    });

    if (votedRates) totalScore /= votedRates;
    document.getElementById("total_score").innerText = parseFloat(totalScore).toFixed(2);
  }

  scrollUp() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  save() {
    const feedbackUrl = document.getElementById("feedback_url").value;
    const feedbackType = document.getElementById("feedback_type").value;

    fetch(feedbackUrl, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": getCsrfToken()
      },
      body: JSON.stringify(this.prepareFeedbackData(feedbackType))
    }).then(response => response.json())
      .then(data => {
        if (data.errors) {
          Object.values(data.errors).forEach((message) => {
            this.showNotification(message, "warning");
          });

          return;
        }

        const totalScore = document.getElementById("total_score");
        totalScore.innerText = parseFloat(data.total_score).toFixed(2);
        this.showNotification(data.message, "success");
      })
  }
}
