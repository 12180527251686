import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["mapData"];
  static values = { lat: Number, lng: Number };

  connect() {
    // quickfix. disabling google maps alert about deprecated MarkerClusterer.
    localStorage.setItem("lastExecutionTime", new Date(2124, 6, 3).getTime());
    this.mapData = JSON.parse(this.mapDataTarget.value).map_data;
    this.overlays = [];

    this.initMap();
  }

  initMap() {
    if (!this.mapData) {
      return;
    }

    const options = {
      zoom: 10,
      center: { lat: this.latValue, lng: this.lngValue },
    };

    this.map = new google.maps.Map(document.getElementById("map"), options);
    if (window.screen.width < 800) {
      this.map.setZoom(16);
    }

    this.initOverlays();
    this.initMarkerClusters();
  }

  onListingsAdded() {
    this.mapData = JSON.parse(this.mapDataTarget.value).map_data;

    this.initOverlays();
    this.addMarkerClusters();

    let markers = this.markerCluster.markers_.map((x) => x.latlng_ )
    var bounds = new google.maps.LatLngBounds();
    for (let i = 0; i < markers.length; i++) {
      bounds.extend(markers[i]);
    }
    this.map.fitBounds(bounds)
  }

  initOverlays() {
    const this_ = this;

    this.mapData.forEach((data) => {
      const latlng = new google.maps.LatLng(data.latitude, data.longitude);

      const window = new google.maps.InfoWindow({
        content: `<a href="${data.url}">
                    <div class="info-window">
                      <div class="image-box">
                        <img src=${data.image}>
                      </div>
                      <div class="content">
                        <h4 class="title">${data.title}</h4>
                        <span>${data.buildingType}</span>
                      </div>
                      <p class="price">${data.details}</p>
                    </div>
                  </a>`,
      });

      const overlay = new ListingOverlay(this_.map, latlng, data.price, window, data.id, this_.overlays);
      this_.overlays.push(overlay);
    });
  }

  initMarkerClusters() {
    const this_ = this;
    const markerClusterOptions = {
      maxZoom: 9,
      styles: [
        {
          height: 53,
          url: "/assets/cluster-image-4505704cd60c3be19634fc254fb445dc1f5e935c0f9111115c49a4b11c588b56.png",
          width: 53,
          textSize: "16",
          fontWeight: "800",
          textColor: "#fff",
          backgroundPosition: "center center",
          boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.25);",
        },
      ],
    };

    this.markerCluster = new MarkerClusterer(this.map, this.overlays, markerClusterOptions, {});

    google.maps.event.addListener(this.markerCluster, "clusterclick", () => {
      for (const listing of this_.overlays) {
        listing.infoWindow.close();

        if (listing.div) {
          listing.div.classList.remove("rounded-div-marker");
          listing.div.querySelector("#place_price").style.display = "block";
          listing.div.querySelector(".dot-marker").style.display = "none";
        }
      }
    });
  }

  addMarkerClusters() {
    const newListingIds = this.mapData.map((l) => l.id);
    const newOverlays = this.overlays.filter((overlay) => newListingIds.includes(overlay.id));
    this.markerCluster.addMarkers(newOverlays, true);
  }
}

class ListingOverlay extends google.maps.OverlayView {
  constructor(map, latlng, value, infoWindow, id, overlays) {
    super();
    this.id = id;
    this.latlng_ = latlng;
    this.value = value;
    this.infoWindow = infoWindow;
    // we initialize each listing overlay with a reference to the overlays list,
    // so we can use it with onClick listener (to hide other overlays).
    this.overlays = overlays;
    this.div = this.buildInfoBlock();
    this.setMap(map);
  }

  onAdd() {
    if (!this.div) {
      this.div = this.buildInfoBlock();
    }

    const panes = this.getPanes();
    panes.overlayMouseTarget.appendChild(this.div);

    this.addEventListenerOnInfoBlock();
  }

  draw() {
    const point = this.getProjection().fromLatLngToDivPixel(this.latlng_);

    if (point) {
      this.div.style.left = point.x + "px";
      this.div.style.top = point.y + "px";
    }
  }

  remove() {
    if (this.div) {
      this.div.parentNode.removeChild(this.div);
      this.div = null;
    }
  }

  getPosition = function () {
    return this.latlng_;
  };

  buildInfoBlock() {
    const div = document.createElement("DIV");
    div.className = "tooltip div-markers";
    div.id = "tooltip_" + this.id;
    div.style.position = "absolute";
    div.style.backgroundColor = "#fff";
    div.style.border = "1px solid var(--primary-color)";
    div.style.padding = "10px 16px";
    div.style.borderRadius = "4px";
    div.style.cursor = "pointer";
    div.style.opacity = 1;

    const span = document.createElement("span");
    span.style.color = "#393F3E";
    span.id = "place_price";
    span.style.fontWeight = "800";
    span.style.fontSize = "12px";
    span.className = "markerOverlay";
    span.appendChild(document.createTextNode(this.value));
    div.appendChild(span);

    const roundedMarker = document.createElement("span");
    roundedMarker.className = "dot-marker";
    div.appendChild(roundedMarker);

    return div;
  }

  addEventListenerOnInfoBlock() {
    const this_ = this;

    google.maps.event.addDomListener(this_.div, "click", () => {
      const others = this_.overlays.filter((listing) => listing.id !== this_.id);

      // close other listing blocks if they exist
      for (const listing of others) {
        listing.infoWindow.close();

        if (listing.div) {
          listing.div.classList.remove("rounded-div-marker");
          listing.div.querySelector(".dot-marker").style.display = "none";
          listing.div.querySelector(`#place_price`).style.display = "block";
        }
      }

      if (this_.infoWindow.isOpen) {
        this_.infoWindow.close();
      } else {
        this_.infoWindow.open(this_.getMap(), this_);
      }

      if (this_.div.querySelector("#place_price").style.display !== "none") {
        this_.div.classList.add("rounded-div-marker");
        this_.div.querySelector("#place_price").style.display = "none";
        this_.div.querySelector(".dot-marker").style.display = "block";
      } else {
        this_.div.classList.remove("rounded-div-marker");
        this_.div.querySelector("#place_price").style.display = "block";
        this_.div.querySelector(".dot-marker").style.display = "none";
      }
    });
  }
}
