import { Controller } from 'stimulus'
import $ from "jquery";
import getCsrfToken from "../utils/csrf";

export default class extends Controller {
    static targets = ["icon"];

    toggle() {
        if (this.iconTarget.classList.contains(this.getOffClass())) {
            this.create();
        } else {
            this.destroy();
        }
    }

    create() {
        const receiptId = this.data.get("receipt-id");
        const url = this.data.get('create-url');
        fetch(url, {
            method: 'POST',
            headers: { 'X-CSRF-Token': getCsrfToken() }
        })
        .then(response => response.text())
        .then(data => {
            const pinsCounter = $('.header__info .pins_counter span');
            const pinnedCount = JSON.parse(data).pinned_count;
            pinsCounter.text(pinnedCount);
            this.enable();
        })
        .catch(error => {
            // display error.
        })
    }

    destroy() {
        const receiptId = this.data.get("receipt-id");
        const url = this.data.get('destroy-url');
        fetch(url, {
            method: 'DELETE',
            headers: { 'X-CSRF-Token': getCsrfToken() }
        })
        .then(response => response.text())
        .then(data => {
            const pinIcon = $('.header__info .pins_counter i.icon-pin');
            if (pinIcon.hasClass('active')) $(this.element).closest('.timeline__message').remove();

            const pinsCounter = $('.header__info .pins_counter span');
            const pinnedCount = JSON.parse(data).pinned_count;
            pinsCounter.text(pinnedCount);
            this.disable();
        })
        .catch(error => {
            // display error.
        })
    }

    enable() {
        this.iconTarget.classList.replace(this.getOffClass(), this.getOnClass());
    }

    disable() {
        this.iconTarget.classList.replace(this.getOnClass(), this.getOffClass());
    }

    getOnClass() {
        return this.data.get("on-class");
    }

    getOffClass() {
        return this.data.get("off-class");
    }

}
