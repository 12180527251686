import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['areaCarousel', 'collectionCarousel']

  connect() {
    if (this.hasAreaCarouselTarget) {
      this.areaSlider()
    }else{
      this.colleactionSlider()
    }
  }

  areaSlider(){
    $(this.areaCarouselTarget).slick({
      infinite: true,
      slidesToShow: 4,
      slidesToScroll: 1,
      autoplay: true,
      arrows: true,
      'prevArrow': '<button class="slide-arrow prev-arrow"></button>',
      'nextArrow': '<button class="slide-arrow next-arrow"></button>',
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            arrows: false,
            slidesToScroll: 1,
            infinite: true
          }
        }
      ]
    })
  }

  colleactionSlider(){
    $(this.collectionCarouselTarget).slick({
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: true,
      arrows: true,
      'prevArrow': '<button class="slide-arrow prev-arrow"></button>',
      'nextArrow': '<button class="slide-arrow next-arrow"></button>',
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            arrows: false,
            slidesToScroll: 1,
            infinite: true
          }
        }
      ]
    })
  }
}