import { Controller } from "stimulus"

import SimpleLightbox from "simplelightbox/src/simple-lightbox.js";

export default class extends Controller {
  static targets = ["lightbox"]

  connect() {
    let lightbox = new SimpleLightbox(`.${this.lightboxTarget.dataset.lightboxId} a`, { 
      overlayOpacity: 1.0,
      enableKeyboard: true,
    });
  }
}
