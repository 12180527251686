import { Controller } from "stimulus"

import { contentLoaderDone } from "../events/content_loader";
import { throttle} from "underscore";

export default class extends Controller {
  initialize() {
    this.loadThrottled = throttle(this.load, 1000);
  }

  connect() {
    if (this.immediate()) {
      this.load()
    }

    if (this.data.has("refreshInterval") && Number(this.data.get("refreshInterval")) > 0) {
      this.startRefreshing();
    }
  }

  immediate() {
    if(!this.data.has("immediate")) {
      // if not specified, immediate true is default.
      return true;
    }

    return this.data.get("immediate") === "true";
  }

  startRefreshing() {
    const interval = Number(this.data.get("refreshInterval"));

    setTimeout(() => {
      this.loadThrottled();
    }, 5000);
  }

  load() {
    const filterPrefix = this.data.get('filter')
    const urlElements = document.querySelectorAll("[name^='"+ filterPrefix +"']")
    const paramsJSON = {}
    urlElements.forEach(e => {
      var value, name;
      name = e.name.replace(filterPrefix, '').replaceAll('[','').replaceAll(']','')
      if (e.nodeName === 'SELECT'){
        const selected = []
        for (var option of e.options){
          if (option.selected && !!option.value) {
            selected.push(option.value);
          }
        }
        value = selected
      }
      else if (name === 'location_type') {
        value = e.value.split(' ')
      }
      else if (e.type === 'checkbox'){
        const selected = []
        for (var option of $("#search_space_usage_type_ :checkbox:checked")){
          if (option.checked && !!option.value) {
            selected.push(option.value);
          }
        }
        value = selected
      }
      else if (e.type !== 'checkbox') {
        value = e.value
      }
      if (Array.isArray(value) && !value.length) return
      paramsJSON[name] = value
    })
    const filterParams = new URLSearchParams(Object.entries(paramsJSON)).toString()
    const url = this.data.get("url")
    fetch([url, filterParams].filter(Boolean).join("?"))
      .then(response => response.text())
      .then(html => {
        this.element.outerHTML = html;
        contentLoaderDone();
      })
  }
}
