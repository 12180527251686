import { Controller } from "stimulus";
import { getVenue, showVenue, E_SDK_EVENT, COLLISION_RANKING_TIERS, CAMERA_EASING_MODE } from "@mappedin/mappedin-js";
import "@mappedin/mappedin-js/lib/mappedin.css";
import _ from "underscore";

export default class extends Controller {
  static targets = ["map", "levelSelect", "level"];

  async connect() {
    this.listings = [];

    const options = {
      venue: this.venueId,
      clientId: this.clientId,
      clientSecret: this.clientSecret,
    };

    if (this.venuePerspective && this.venuePerspective !== "") {
      options.perspective = this.venuePerspective;
    }

    this.venue = await getVenue(options);
    this.mapView = await showVenue(this.mapTarget, this.venue);

    if (this.locationId) {
      this.markLocation(this.locationId);
      return;
    }

    const maps = this.venue.maps.sort((a, b) => b.elevation - a.elevation);
    this.populateMaps(maps, this.venue.venue.defaultMap);

    await fetch(`${this.listingsUrl}?format=json`)
      .then((response) => response.json())
      .then(({ listings }) => {
        this.listings = listings;
        this.renderLocations();
      })
      .catch((error) => console.error(error));

    this.mapView.on(E_SDK_EVENT.CLICK, this.onMapClick.bind(this));
  }

  populateMaps(maps, defaultMap) {
    maps.forEach((map, ind) => {
      const label = document.createElement("label");
      label.className = "px-6 py-18 cursor-pointer border-y border-l last:border-r first:rounded-l last:rounded-r";
      if (map.id === defaultMap) {
        label.classList.add("bg-cta-color", "text-white");
      } else {
        label.classList.add("bg-white", "text-gray-800");
      }
      label.setAttribute("data-mappedin-target", "level");
      label.setAttribute("data-action", "click->mappedin#onLevelChange");
      label.setAttribute("data-index", ind);

      const input = document.createElement("input");
      input.type = "radio";
      input.value = map.id;
      input.name = "mappedin_level";
      input.className = "hidden";

      const labelText = document.createTextNode(map.name);

      label.appendChild(input);
      label.appendChild(labelText);
      this.levelSelectTarget.appendChild(label);
    });

    this.levelSelectTarget.classList.remove("hidden");
  }

  renderLocations() {
    this.venue.polygons.forEach((polygon) => {
      const location = _.findWhere(this.listings, { external_id: polygon.externalId });
      if (location === undefined) return;

      this.mapView.FloatingLabels.add(polygon, location.title, { interactive: true });
      this.mapView.addInteractivePolygon(polygon);
    });
  }

  addTooltip(polygon) {
    this.removeTooltip();
    this.mapView.setPolygonColor(polygon, "#18BC9C");
    fetch(`${this.listingsUrl}/${polygon.externalId}`)
      .then((response) => response.text())
      .then((tooltipHtml) => {
        // this.mapView.Camera.focusOn({ polygons: polygons });
        this.tooltip = this.mapView.createTooltip(polygon.entrances[0], tooltipHtml, {
          defaultAnchorType: "top",
          collisionRank: COLLISION_RANKING_TIERS.ALWAYS_VISIBLE,
        });
      })
      .catch((error) => console.error(error));
  }

  removeTooltip() {
    if (this.tooltip) {
      this.mapView.removeTooltip(this.tooltip);
    }
    this.renderLocations();
    this.mapView.clearAllPolygonColors();
  }

  onMapClick({ polygons, floatingLabels }) {
    if (polygons.length > 0) {
      this.addTooltip(polygons[0]);
    } else if (floatingLabels && floatingLabels.length > 0) {
      this.addTooltip(floatingLabels[0].node.polygon);
    } else {
      this.removeTooltip();
    }
  }

  onLevelChange(event) {
    event.preventDefault();

    const map = event.currentTarget.querySelector("input").value;
    this.mapView.setMap(map);

    const selectedIndex = event.currentTarget.dataset.index;
    if (selectedIndex === undefined) {
      return;
    }

    this.levelTargets.forEach((item, i) => {
      if (Number(i) === Number(selectedIndex)) {
        item.classList.add("bg-cta-color", "text-white");
        item.classList.remove("bg-white", "text-gray-800");
      } else {
        item.classList.remove("bg-cta-color", "text-white");
        item.classList.add("bg-white", "text-gray-800");
      }
    });
  }

  markLocation(externalId) {
    const polygon = this.venue.polygons.find((poly) => poly.externalId === externalId);
    if (polygon === undefined) {
      return;
    }

    this.mapView
      .setMap(polygon.map)
      .then(() => {
        this.mapView.setPolygonColor(polygon, "#18BC9C");
        this.mapView.Camera.focusOn(
          {
            polygons: [polygon],
          },
          {
            minZoom: 1500,
            duration: 1000,
            easing: CAMERA_EASING_MODE.EASE_IN_OUT,
          }
        );
      })
      .catch((error) => {
        console.log(error);
      });
  }

  get locationId() {
    return this.data.get("locationId");
  }

  get venueId() {
    return this.data.get("venue");
  }

  get venuePerspective() {
    return this.data.get("venuePerspective");
  }

  get clientId() {
    return this.data.get("clientId");
  }

  get clientSecret() {
    return this.data.get("clientSecret");
  }

  get listingsUrl() {
    return this.data.get("listingsUrl");
  }
}
