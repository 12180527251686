import { Controller } from "stimulus"
import openModal from "../utils/remote-modal";

export default class extends Controller {
  static targets = ['loaderContainer']
  static values = { customEvent: String }

  connect() {
    this.injectedComponents = [];

    document.addEventListener(
      "turbolinks:before-cache",
      () => {
        this.teardown();
      },
      { once: true }
    );

    this.isInProgress = false;
  }

  inject(event) {
    const url = this.data.get('url');
    let originalText;
    let intervalId;

    if (this.isInProgress) return
    this.isInProgress = true

    if (this.hasLoaderContainerTarget) {
      let dotCount = 1;
      const loadingText = 'Loading';
      originalText = this.loaderContainerTarget.innerText

      intervalId = setInterval(() => {
        this.loaderContainerTarget.innerText = loadingText + '.'.repeat(dotCount);
        dotCount = dotCount % 3 + 1;
      }, 500);
    }

    openModal(url, (id) => {
      this.injectedComponents.push(id);
      this.isInProgress = false

      if (this.hasLoaderContainerTarget) {
        clearInterval(intervalId);
        this.loaderContainerTarget.innerText = originalText
      }

      if (this.hasCustomEventValue) {
        document.dispatchEvent(new CustomEvent(this.customEventValue))
      }
    })
  }

  teardown() {
    this.injectedComponents.forEach(id => $('#' + id).remove())
  }
}
