import { Controller } from "stimulus";
import { Chart } from "chart.js";
import {
  totalRent,
  totalStaff,
  totalFurniture,
  totalMarketingAndPaymentSystems,
  totalLegal,
  total,
  minimalRevenue,
  totalProfit,
  totalOther,
  percentage,
} from "../budget_calculation";

export default class extends Controller {
  static targets = [
    "shopTypeFactor",
    "shopConceptFactor",
    "rentalLength",
    "rentPerSqm",
    "shopSize",
    "staffCount",
    "adminOther",
    "profitMarginExpected",
    "rentTotal",
    "rentPercent",
    "staffTotal",
    "staffPercent",
    "furnitureTotal",
    "furniturePercent",
    "marketingTotal",
    "marketingPercent",
    "otherTotal",
    "otherPercent",
    "legalTotal",
    "legalPercent",
    "costTotal",
    "profitTotal",
    "minimumRevenue",
    "chart",
  ];

  connect() {
    let ctx = this.chartTarget.getContext('2d');
    this.chart = new Chart(ctx, this.chartConfig({ rent: 1000, staff: 0, furniture: 0, marketing: 0, other: 0, legal: 0 }));
    this.calculate();
  }

  disconnect() {
    this.chart.destroy();
  }

  calculate() {
    let shopTypeFactor = parseFloat(this.shopTypeFactorTarget.value) || 0;
    let shopConceptFactor = parseFloat(this.shopConceptFactorTarget.value) || 0;
    let rentalLength = parseFloat(this.rentalLengthTarget.value) || 0;
    let shopSize = parseFloat(this.shopSizeTarget.value) || 0;
    let staffCount = parseFloat(this.staffCountTarget.value) || 0;
    let adminOther = parseFloat(this.adminOtherTarget.value) || 0;
    let profitMarginExpected =
      parseFloat(this.profitMarginExpectedTarget.value) || 0;
    let rentPercent = this.rentPercentTarget.value || 0;
    let staffPercent = this.staffPercentTarget.value || 0;
    let furniturePercent = this.furniturePercentTarget.value || 0;
    let marketingPercent = this.marketingPercentTarget.value || 0;
    let otherPercent = this.otherPercentTarget.value || 0;
    let legalPercent = this.legalPercentTarget.value || 0;

    let ratePerStaff = parseFloat(this.data.get("staff-rate")) || 0;
    let furnitureCost = parseFloat(this.data.get("furniture")) || 0;
    let paymentSystemsCost = parseFloat(this.data.get("payment")) || 0;
    let legalCost = parseFloat(this.data.get("legal")) || 0;
    let marketingCost = parseFloat(this.data.get("marketing")) || 0;
    let rentPerSqm = parseFloat(this.data.get("rent-per-sqm")) || 0;
    let locale = this.data.get("locale") || 'en';
    let currency = this.data.get("currency") || 'CHF';

    let rent = totalRent({ shopSize, shopTypeFactor, rentalLength, rentPerSqm });
    this.rentTotalTarget.innerText = this.formatCurrency({number: rent, locale, currency})

    let staff = totalStaff({ staffCount, rentalLength, ratePerStaff });
    this.staffTotalTarget.innerText = this.formatCurrency({ number: staff, locale, currency});

    let furniture = totalFurniture({ shopSize, rentalLength, furnitureCost });
    this.furnitureTotalTarget.innerText = this.formatCurrency({number: furniture, locale, currency});

    let marketing = totalMarketingAndPaymentSystems({
      marketingCost,
      paymentSystemsCost,
      shopConceptFactor,
    });
    this.marketingTotalTarget.innerText = this.formatCurrency({number: marketing, locale, currency });

    let legal = totalLegal({ legalCost, shopTypeFactor });
    this.legalTotalTarget.innerText = this.formatCurrency({ number: legal, locale, currency });

    let subTotal = total([rent, staff, furniture, marketing, legal]);
    let other = totalOther({ adminOther, cost: subTotal });
    this.otherTotalTarget.innerText = this.formatCurrency({ number: other, locale, currency });

    //display calulated totals:

    let cost = total([subTotal, other]);
    this.costTotalTarget.innerText = this.formatCurrency({ number: cost, locale, currency });

    let minimumRevenue = minimalRevenue({
      totalCost: cost,
      profitMarginExpected,
    });
    this.minimumRevenueTarget.innerText = this.formatCurrency({number: minimumRevenue, locale, currency});

    let profitTotal = totalProfit({
      minimalRevenue: minimumRevenue,
      totalCost: cost,
    });
    this.profitTotalTarget.innerText = this.formatCurrency({ number: profitTotal, locale, currency });

    //display calculated percentages:

    let rentShare = percentage({ part: rent, total: cost });
    this.rentPercentTarget.innerText = `${rentShare} %`;

    let furnitureShare = percentage({ part: furniture, total: cost });
    this.furniturePercentTarget.innerText = `${furnitureShare} %`;

    let staffShare = percentage({ part: staff, total: cost });
    this.staffPercentTarget.innerText = `${staffShare} %`;

    let marketingShare = percentage({ part: marketing, total: cost });
    this.marketingPercentTarget.innerText = `${marketingShare} %`;

    let otherShare = percentage({ part: other, total: cost });
    this.otherPercentTarget.innerText = `${otherShare} %`;

    let legalShare = percentage({ part: legal, total: cost });
    this.legalPercentTarget.innerText = `${legalShare} %`;

    this.updateChart({ rent, staff, furniture, marketing, other, legal })
  }

  chartConfig({ rent, staff, furniture, marketing, other, legal }) {
    return {
      type: 'doughnut',
      data: {
        datasets: [{
          data: [
            rent,
            staff,
            furniture,
            marketing,
            other,
            legal
          ],
          backgroundColor: [
            'rgb(228, 146, 110)',
            'rgb(24, 188, 156)',
            'rgb(251, 238, 194)',
            'rgb(246, 203, 183)',
            'rgb(209, 209, 180)',
            'rgb(234, 234, 231)'
          ],
        }],
        labels: []
      },
      options: {
        responsive: true,
        borderWidth: 40,
        cutoutPercentage: 45,
        weight: 40,
        hover: false,
        tooltips: false,
        legend: {
          display: false,
        },
        animation: {
          animateScale: false,
          animateRotate: true
        }
      }
    }
  }

  updateChart({ rent, staff, furniture, marketing, other, legal }) {
    this.chart.config = this.chartConfig({ rent, staff, furniture, marketing, other, legal })
    this.chart.update()
  }

  formatCurrency({number, locale, currency}) {
    return number.toLocaleString(locale, { style: "currency", currency: currency, minimumFractionDigits: 0 })
  }
}
