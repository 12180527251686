import { Controller } from "stimulus";

import mapboxgl from "../../../../assets/javascripts/vendor/mapbox-gl";
import "../../../../assets/stylesheets/vendor/mapbox-gl.css";

export default class extends Controller {
  static targets = ["map"];

  connect() {
    const { lat, lng } = this.mapTarget.dataset;
    this.initialize_map(lat, lng);
  }

  initialize_map(lat, lng) {
    let zoom = 13;
    if (!!this.footfallUrl && this.country === "US") {
      zoom = 10;
    }

    const map = new mapboxgl.Map({
      accessToken: this.accessToken,
      container: this.mapTarget,
      center: [lng, lat], // starting position [lng, lat]
      zoom: zoom, // starting zoom
    });

    map.addControl(new mapboxgl.NavigationControl());

    map.on("load", () => {
      map.addSource("source_circle", {
        type: "geojson",
        data: {
          type: "FeatureCollection",
          features: [
            {
              type: "Feature",
              geometry: {
                type: "Point",
                coordinates: [lng, lat],
              },
            },
          ],
        },
      });

      map.addLayer({
        id: "circle",
        type: "circle",
        source: "source_circle",
        paint: {
          "circle-radius": {
            stops: [
              [0, 0],
              [20, this.metersToPixelsAtMaxZoom(120, lat)],
            ],
            base: 2,
          },
          "circle-color": "#18BC9C",
          "circle-opacity": 0.4,
          "circle-stroke-color": "#15a589",
          "circle-stroke-opacity": 1,
          "circle-stroke-width": 3,
        },
      });

      if (!this.footfallUrl || !this.country) {
        return;
      }

      if (this.country === "CH") {
        this.addSwisscomData(map, lat, lng);
      } else if (this.country === "US") {
        this.addUnacastData(map, lat, lng);
      }
    });
  }

  addUnacastData(map, lat, lng) {
    fetch(`${this.footfallUrl}?lat=${lat}&lng=${lng}&source=unacast`)
      .then((response) => response.json())
      .then((data) => {
        map.addSource("source_unacast", {
          type: "geojson",
          data: data,
        });

        const radiusToColorMap = {
          5000: "#EC1B40",
          10000: "#2E907D",
          25000: "#298AB4",
          50000: "#393F3E",
        };

        const legendLabels = _.map(data.features, (f) => {
          let radiusKM = parseInt(f.properties.radius) / 1000;
          return {
            color: radiusToColorMap[f.properties.radius.toString()],
            value: `${radiusKM}km - ${Math.round(f.properties.footfall)}`,
          };
        });
        const legend = document.getElementById("mapbox-legend");

        for (let label of legendLabels) {
          let labelElement = document.createElement("div");
          labelElement.innerHTML = `<span style="background-color: ${
            label.color
          }" class="inline-block h-[10px] w-[10px] mr-[5px] rounded-full"></span>${label.value}`;
          legend.appendChild(labelElement);
        }
        legend.classList.remove("hidden");

        map.addLayer({
          id: "unacast_circles",
          type: "circle",
          source: "source_unacast",
          paint: {
            "circle-radius": ["interpolate", ["exponential", 2], ["zoom"], 0, 0, 20, ["get", "radiusInPx"]],
            "circle-color": "rgba(0, 0, 0, 0)",
            "circle-opacity": 0,
            "circle-stroke-color": [
              "interpolate",
              ["linear"],
              ["get", "radius"],
              5000,
              radiusToColorMap["5000"],
              10000,
              radiusToColorMap["10000"],
              25000,
              radiusToColorMap["25000"],
              50000,
              radiusToColorMap["50000"],
            ],
            "circle-stroke-opacity": 1,
            "circle-stroke-width": 5,
          },
        });
      });
  }

  addSwisscomData(map, lat, lng) {
    map.addSource("footfall", {
      type: "geojson",
      data: `${this.footfallUrl}?lat=${lat}&lng=${lng}&source=swisscom`,
    });

    map.addLayer({
      id: "footfall-tiles",
      type: "fill",
      source: "footfall",
      layout: {},
      paint: {
        "fill-color": ["interpolate", ["linear"], ["get", "value"], 1, "rgb(255, 255, 0)", 10, "rgb(255, 0, 0)"],
        "fill-opacity": 0.7,
      },
    });
  }

  metersToPixelsAtMaxZoom(meters, latitude) {
    return meters / 0.075 / Math.cos((latitude * Math.PI) / 180);
  }

  get accessToken() {
    return this.data.get("accessToken");
  }

  get footfallUrl() {
    return this.data.get("footfallUrl");
  }

  get country() {
    return this.data.get("country");
  }
}
