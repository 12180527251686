import Sortable from "stimulus-sortable"
import getCsrfToken from "../utils/csrf";

export default class extends Sortable {
  // Changes default stimulus sortable behaviour
  // To enable saving sorting on backend container element should have controller and url provided:
  // data-controller="sortable" data-sortable-update-url="/path/to/submit/sorting"
  // and container's child elements should have data-id="id-of-sortable-element"

  end() {
    super.end();

    const url = this.sortable.el.dataset.sortableUpdateUrl;
    if (!url) return;

    const ids = Array.from(this.sortable.el.children).map(x => {
      if (x.dataset.id) {
        return x.dataset.id
      }
    })

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": getCsrfToken()
      },
      body: JSON.stringify({
        attachment: ids
      })
    });
  }
}