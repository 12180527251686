import { ApplicationController } from "stimulus-use";
import moment from "moment";
import { t } from "../i18n/t";

export default class extends ApplicationController {
  static targets = [
    "type",
    "from",
    "to",
    "price",
    "industry",
    "submitBtn",
    "offerBtn",
    "bookingBtn",
    "closeBtn",
    "shortForm",
    "detailedForm",
  ];

  connect() {
    if (this.hasOfferBtnTarget) {
      this.offerBtnTarget.onclick = this.onOfferButtonClick.bind(this);
    }

    if (this.hasBookingBtnTarget) {
      this.bookingBtnTarget.onclick = this.onConfirmedBookingButtonClick.bind(this);
    }

    this.closeBtnTarget.onclick = this.onCloseButtonClick.bind(this);
  }

  onPriceChanged(event) {
    this.updatePriceWidget(event.detail.value);
  }

  updatePriceWidget(rent) {
    const startDateStr = this.fromTarget.value;
    const endDateStr = this.toTarget.value;

    let halfDayInSeconds = 0.5 * 24 * 3600;
    let startUTCTimestamp = moment(startDateStr, this.dateFormat).toDate().getTime() / 1000 + halfDayInSeconds;
    let endUTCTimestamp = moment(endDateStr, this.dateFormat).toDate().getTime() / 1000 + halfDayInSeconds;

    this.refreshPrice(startUTCTimestamp, endUTCTimestamp, rent);
  }

  onDatesChanged() {
    const startDateStr = this.fromTarget.value;
    const endDateStr = this.toTarget.value;

    if (startDateStr === "" || endDateStr === "") {
      // console.log('one of the dates is empty');
      return;
    }

    let halfDayInSeconds = 0.5 * 24 * 3600;
    let startUTCTimestamp = moment(startDateStr, this.dateFormat).toDate().getTime() / 1000 + halfDayInSeconds;
    let endUTCTimestamp = moment(endDateStr, this.dateFormat).toDate().getTime() / 1000 + halfDayInSeconds;

    this.refreshPrice(startUTCTimestamp, endUTCTimestamp, null);
  }

  onRequiredFieldChanged() {
    const filled = [this.industryTarget].every((field) => {
      return !!field.value;
    });

    this.submitBtnTarget.disabled = !filled;
  }

  onOfferButtonClick() {
    this.updateTypeDependentFields("offer");
    this.shortFormTarget.classList.add("is-hidden");
    this.detailedFormTarget.classList.remove("is-hidden");
  }

  onConfirmedBookingButtonClick() {
    this.updateTypeDependentFields("booking");
    this.shortFormTarget.classList.add("is-hidden");
    this.detailedFormTarget.classList.remove("is-hidden");
  }

  onCloseButtonClick() {
    this.detailedFormTarget.classList.add("is-hidden");
    this.shortFormTarget.classList.remove("is-hidden");

    const widget = document.querySelector("#inquiry_widget");
    widget.scrollIntoView();
  }

  refreshPrice(start, end, rent) {
    let rentStr = rent == null ? "" : rent;
    const displayDiscountFlag = this.typeTarget.value === "offer";

    fetch(
      `${this.priceUrl}?listing_id=${this.listingId}&start=${start}&end=${end}&rent_total=${rentStr}&display_discount_flag=${displayDiscountFlag}&record_name=booking`
    )
      .then((response) => response.text())
      .then((html) => {
        this.priceTarget.innerHTML = html;
        this.priceTarget.style.display = "block";
      });
  }

  updateTypeDependentFields(type) {
    this.typeTarget.value = type;
    this.toggleTenantPaidField(type);
    this.toggleDisableEmailToTenant(type);
    this.toggleExpiresInField(type);
    this.updateSubmitButton(type);
    this.updatePriceWidget(this.originalRentTotal);
  }

  toggleTenantPaidField(type) {
    const tenantPaidField = document.querySelector(".tenant_paid_field");

    if (!tenantPaidField) {
      return;
    }

    if (type === "booking") {
      tenantPaidField.classList.remove("is-hidden");
    } else {
      tenantPaidField.classList.add("is-hidden");
    }
  }

  toggleDisableEmailToTenant(type) {
    const disableEmailToTenantField = document.querySelector(".disable_email_to_tenant_field");

    if (!disableEmailToTenantField) {
      return;
    }

    if (type === "booking") {
      disableEmailToTenantField.classList.remove("is-hidden");
    } else {
      disableEmailToTenantField.classList.add("is-hidden");
    }
  }

  toggleExpiresInField(type) {
    const expiresInField = document.querySelector(".expires_in_field");

    if (!expiresInField) {
      return;
    }

    if (type === "booking") {
      expiresInField.classList.add("is-hidden");
    } else {
      expiresInField.classList.remove("is-hidden");
    }
  }

  updateSubmitButton(type) {
    this.submitBtnTarget.innerText = t(`offers.controls.${type}`, this.locale);
  }

  get locale() {
    return this.data.get("locale");
  }

  get originalRentTotal() {
    return this.data.get("originalRentTotal");
  }

  get listingId() {
    return this.data.get("listingId");
  }

  get checkUrl() {
    return this.data.get("checkUrl");
  }

  get priceUrl() {
    return this.data.get("priceUrl");
  }

  get dateFormat() {
    return this.data.get("dateFormat");
  }
}
