import { Controller} from "stimulus"

export default class extends Controller {
  static targets = ["tabs", "discountForm", "discountLine"]

  connect() {
    setInterval(() => {
      const paymentTab = this.tabsTarget.querySelector("#payment");
      const discountForms = this.discountFormTargets;

      if (paymentTab.classList.contains("inactive")) {
        discountForms.forEach((form) => {
          form.style.display = "none";
          let discountLine = form.parentElement.querySelector("[data-bookings-target=discountLine]");
          if (discountLine) discountLine.style.display = "none";
        });
      } else {
        discountForms.forEach((form) => {
          form.style.display = "block";
          let discountLine = form.parentElement.querySelector("[data-bookings-target=discountLine]");
          if (discountLine) discountLine.style.display = "block";
        });
      }
    }, 500);
  }
}
