import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["navlink"]
  connect() {
    $('li .nav-link').click(function(e) {
      e.preventDefault();
      $('.nav-link').removeClass('navbar-link-active');
      $(this).addClass('navbar-link-active');
    });
  }
  goTo() {
    $('html, body').animate({
      scrollTop: $(`#${event.currentTarget.dataset.navbarValue}`).offset().top - 100
    }, 200);
  }	
}