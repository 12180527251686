import { Controller } from "stimulus";

import { Calendar } from "@fullcalendar/core";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import interactionPlugin from "@fullcalendar/interaction";
import { t } from "../i18n/t";
import moment from "moment";
import getCsrfToken from "../utils/csrf";

export default class extends Controller {
  // static targets = ["scheduler"]

  connect() {
    this.schedulerMount();
    document.addEventListener(
      "turbolinks:before-cache",
      () => {
        this.schedulerUnmount();
      },
      { once: true }
    );
  }

  schedulerMount() {
    const resourcesUrl = this.data.get("resources-url");
    const eventsUrl = this.data.get("events-url");
    const locale = this.data.get("locale") || "en";

    const calendar = new Calendar(this.element, {
      plugins: [resourceTimelinePlugin, interactionPlugin],
      timeZone: "UTC",
      initialView: "resourceTimelineWeeks",
      firstDay: 1,
      weekNumbers: true,
      height: "auto",
      locale: locale.slice(0, 2),
      headerToolbar: {
        left: "today",
        center: "prev,title,next",
        right:
          "resourceTimelineWeeks,resourceTimelineMonth,resourceTimelineQuarter",
      },
      eventClick: function ({ event, jsEvent }) {
        jsEvent.preventDefault();

        let url = event.extendedProps.conversation_link;
        if (url !== undefined && url !== null && url.length > 0) {
          Turbolinks.visit(url);
        }
      },
      eventContent: ({ event }, createElement) => {
        let iconClasses = [];

        if (event.classNames.includes("availability--booking-approved")) {
          iconClasses.push("icon-checkmark-circle");
        } else if (event.classNames.includes("availability--booking-pending")) {
          iconClasses.push("icon-clock");
        } else if (event.classNames.includes("availability--booking-offered")) {
          iconClasses.push("icon-clock");
        }

        return createElement("div", { class: "calendar-event" }, [
          createElement(
            "div",
            { class: "calendar-event-title", title: event.title },
            [
              createElement("i", { class: iconClasses.join(" ") }, null),
              event.title,
            ]
          ),
        ]);
      },
      buttonText: {
        today: t("scheduler.today", locale),
      },
      views: {
        resourceTimelineWeeks: {
          type: "resourceTimeline",
          duration: { days: 15 },
          buttonText: t("scheduler.weeks", locale),
          slotLabelFormat: [{ week: "short" }, { weekday: "short" }, { day: "numeric" }],
        },
        resourceTimelineMonth: {
          type: "resourceTimeline",
          duration: { months: 1 },
          buttonText: t("scheduler.month", locale),
          slotLabelFormat: [{ week: "short" }, { weekday: "short" }, { day: "numeric" }],
        },
        resourceTimelineQuarter: {
          type: "resourceTimeline",
          duration: { months: 3 },
          buttonText: t("scheduler.quarter", locale),
          slotLabelFormat: [
            { month: "long" },
            { week: "short" },
            { weekday: "short" },
            { day: "numeric" },
          ],
        },
      },
      resourceAreaHeaderContent: t("listings.listings_title", locale),
      resourceLabelContent: (arg, createElement) => {
        const url = arg.resource.extendedProps["calendarUrl"];
        const title = arg.resource.title;
        return createElement(
          "a",
          { href: url, "data-turbolinks": "false" },
          title
        );
      },
      resourceGroupField: "building",
      resources: resourcesUrl,
      resourceOrder: "index",
      events: function (info, successCallback) {
        fetch(`${eventsUrl}&start=${info.startStr}&end=${info.endStr}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "X-CSRF-Token": getCsrfToken(),
          },
        })
          .then((response) => response.json())
          .then((data) => successCallback(data));
      },
      slotDuration: "24:00:00",

      // License
      schedulerLicenseKey: "0903568687-fcs-1610921093",
    });

    calendar.render();
    this.calendar = calendar
  }

  schedulerUnmount() {
    //not needed?
    //this.element.fullCalendar('destroy');
  }
}
