import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    document.addEventListener("pos/stripe_account_updated", this.onSettingUpdate, false);
  }

  onSettingUpdate() {
    Turbolinks.clearCache();
    Turbolinks.visit(window.location.pathname);
  }
}
