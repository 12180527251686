import { Controller } from "stimulus"
import $ from "jquery"
import "slick-carousel/slick/slick"

export default class extends Controller {
  static targets = ["carousel"]

  connect() {
    $(this.carouselTarget).slick({
      dots: false,
      infinite: true,
      speed: this.data.get('speed') || 300,
      slidesToShow: this.data.get('slides-per-view') || 3,
      slidesToScroll: 1,
      autoplay: true,
      arrows: true,
      prevArrow:"<button type='button' class='slick-prev pull-left'><span class='icon-chevron-left' aria-hidden='true'></span></button>",
      nextArrow:"<button type='button' class='slick-next pull-right'><span class='icon-chevron-right' aria-hidden='true'></span></button>",
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true
          }
        },
        {
          breakpoint: 800,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]

    })
  }

  disconnect() {
    $(this.carouselTarget).slick('unslick');
  }
}
