import { Controller } from "stimulus"

export default class extends Controller {

  toggleCards(event) {
    Array.from(event.currentTarget.querySelectorAll(".toggle-display")).map(ele => {
      ele.classList.toggle("hidden")
      ele.classList.toggle("flex")
    })
  }
}