import { Controller } from "stimulus";
import { Calendar } from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import allLocales from "@fullcalendar/core/locales-all";
import _ from "underscore";
import moment from "moment";

export default class extends Controller {
  static targets = ["calendar1", "calendar2", "url", "spinner"];

  connect() {
    const { url } = this.urlTarget.dataset;
    const calendarEl1 = this.calendar1Target;
    const calendarEl2 = this.calendar2Target;
    const calendar_spinner = this.spinnerTarget;
    const that = this;
    $(document).on("turbolinks:load", function () {
      that.initialize_calendar(url, calendarEl1, calendarEl2, calendar_spinner);
    });
  }

  initialize_calendar(url, calendarEl1, calendarEl2, calendar_spinner) {
    const start_date = moment().unix();
    const end_date = moment().add(4, "months").unix();
    const that = this;

    if ($(calendarEl1).length === 0 || $(calendarEl2).length === 0) return;
    $.get(url, { start: start_date, end: end_date }).done(function (res) {
      let eventsData = _.indexBy(res, "day");
      const events = res.map((c) => ({ ...c, start: c.day }));
      const calendar1Id = $(calendarEl1).attr("id");
      const calendar2Id = $(calendarEl2).attr("id");
      const calendar1 = new Calendar(calendarEl1, {
        locales: allLocales,
        locale: that.locale().slice(0, 2),
        height: "335px",
        plugins: [dayGridPlugin, interactionPlugin],
        initialView: "dayGridMonth",
        events,
        eventDidMount: function (arg) {
          setEvents(arg, calendar1Id);
        },
        headerToolbar: {
          left: "custom_prev",
          center: "title",
          right: "custom_next",
        },
        themeSystem: "standard",
        customButtons: {
          custom_prev: {
            text: "<",
            click: function () {
              reloadEvents(false);
            },
          },
          custom_next: {
            text: ">",
            click: function () {
              reloadEvents(true);
            },
          },
        },
        displayEventEnd: true,
        timeZone: "UTC",
        showNonCurrentDates: false,
        firstDay: 1,
      });

      const calendar2 = new Calendar(calendarEl2, {
        locales: allLocales,
        locale: that.locale().slice(0, 2),
        height: "335px",
        plugins: [dayGridPlugin, interactionPlugin],
        initialView: "dayGridMonth",
        events,
        eventDidMount: function (arg) {
          setEvents(arg, calendar2Id);
        },
        headerToolbar: {
          left: "",
          center: "title",
          right: "custom_next",
        },
        themeSystem: "standard",
        initialDate: moment().add(1, "months").format("YYYY-MM-DD"),
        customButtons: {
          custom_next: {
            text: ">",
            click: function () {
              reloadEvents(true);
            },
          },
        },
        displayEventEnd: true,
        timeZone: "UTC",
        showNonCurrentDates: false,
        firstDay: 1,
      });

      const setEvents = (arg, calendarId) => {
        const cellData = $(arg.el).parents("td.fc-daygrid-day");
        const start = arg.event.start;
        const date = new Date(start.getTime() + (start.getTimezoneOffset() * 60000));
        const c = eventsData[moment(date).format("YYYY-MM-DD")];

        if (c) {
          const special_price_comment = c.comment || "";
          let title = special_price_comment;
          if (c.per_day) {
            title = `${c.currency} ${c.per_day}/day<br>${special_price_comment}`;
          }
          if (c.per_week) {
            title = `${c.currency} ${c.per_week}/week<br>${special_price_comment}`;
          }
          if (c.per_month) {
            title = `${c.currency} ${c.per_month}/month<br>${special_price_comment}`;
          }

          cellData.addClass("available-" + c.status);
          if (title) {
            cellData.attr("data-toggle", "tooltip");
            cellData.attr("data-placement", "top");
            cellData.attr("data-container", `#${calendarId}`);
            cellData.attr("data-original-title", title);
            cellData.addClass("special-price")
            cellData.tooltip({ html: true });
          }
        }
      };

      const reloadEvents = (next) => {
        calendar_spinner.style.visibility = "visible";
        calendar_spinner.classList.add("animate-spin");

        let startDate = "";
        let endDate = "";
        if (next) {
          startDate = moment(calendar1.view.activeStart)
            .add(1, "months")
            .unix();
          endDate = moment(calendar1.view.activeEnd).add(2, "months").unix();
        } else {
          startDate = moment(calendar1.view.activeStart)
            .subtract(1, "months")
            .unix();
          endDate = moment(calendar1.view.activeEnd).unix();
        }

        if (next) {
          calendar1.next();
          calendar2.next();
        } else {
          calendar1.prev();
          calendar2.prev();
        }

        $.ajax({
          url: `${url}?start=${startDate}&&end=${endDate}`,
          success: function (res) {
            eventsData = { ...eventsData, ..._.indexBy(res, "day") };
            calendar1.getEvents().forEach((e) => e.remove());
            calendar2.getEvents().forEach((e) => e.remove());
            calendar1.addEventSource(res.map((c) => ({ ...c, start: c.day })));
            calendar2.addEventSource(res.map((c) => ({ ...c, start: c.day })));

            calendar_spinner.classList.remove("background_spinner");
            calendar_spinner.style.visibility = "hidden";
          },
        });
      };
      calendar1.render();
      calendar2.render();
    });
  }

  locale() {
    return this.data.get("locale") || "en";
  }
}
