import { Controller } from "stimulus"

export default class extends Controller {

  static targets = ["progressbar", "attachmentDescription"];
  connect(){}
  
  animateProgressBar(){
    if (this.attachmentDescriptionTarget.value != '' ) {
      if (this.hasProgressbarTarget) {
        document.querySelectorAll('.progress-bar').forEach(function(ele){
          ele.classList.toggle('hidden')
        })
        this.progressbarTarget.style.width='360px'
      }
    }
  }
}
