import { Controller } from "@hotwired/stimulus";
import moment from "moment";

export default class extends Controller {
  static targets = ["availabilityDate"];

  connect() {
    if (this.data.scope.element.dataset.bookmarkId === "") return;

    this.fetchAvailabilityData(this.data.scope.element.dataset.bookmarkId);
  }

  async fetchAvailabilityData(bookmarkId) {
    let startDate = new Date();

    while (true) {
      const startDateStr = startDate.toISOString();
      const endDateStr = new Date(startDate.setMonth(startDate.getMonth() + 1)).toISOString();

      let halfDayInSeconds = 0.5 * 24 * 3600;
      let startUTCTimestamp = moment(startDateStr, this.dateFormat).toDate().getTime() / 1000 + halfDayInSeconds;
      let endUTCTimestamp = moment(endDateStr, this.dateFormat).toDate().getTime() / 1000 + halfDayInSeconds;

      const baseUrl = this.data.scope.element.dataset.bookmarkPath;
      const url = `${baseUrl}?start=${startUTCTimestamp}&end=${endUTCTimestamp}`;

      try {
        const response = await fetch(url);
        const data = await response.json();

        for (let i = 0; i < data.length; i++) {
          if (data[i].status === true) {
            let parts = data[i].day.split('-');
            let date = new Date(parts[0], parts[1] - 1, parts[2]);
            document.getElementById(`availabilityDate-${bookmarkId}`).innerText = moment(date).format('D MMMM YYYY') || 'Not Available';
            return;
          }
        }
      } catch (error) {
        console.error("Error fetching availability data:", error);
      }

      startDate.setMonth(startDate.getMonth() + 2);
    }
  }
}
