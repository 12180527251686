import { Controller } from "stimulus";
import { useDispatch } from "stimulus-use";
import Autonumeric from "autonumeric";

export default class extends Controller {
  static targets = ["input", "source", "editButton", "show", "edit", "discount"];

  connect() {
    useDispatch(this);
    // decimal separator could be comma
    const sourceValue = Number(
      this.sourceTarget.value
        .replace(new RegExp(`[^0-9${this.decimalCharacter}-]+`, 'g'), "")
        .replace(/,/, '.')
    );
    this.originalValue = sourceValue;
    this.updatedValue = sourceValue;
  }

  edit(event) {
    event.preventDefault();
    this.inputTarget.value = this.sourceTarget.value;
    this.toggle(false);
  }

  accept(event) {
    event.preventDefault();
    this.toggle(true);
    console.log(`accepted input: ${this.updatedValue} original: ${this.originalValue}`);
    this.dispatch("accepted", { value: this.updatedValue });
  }

  cancel(event) {
    event.preventDefault();
    this.inputTarget.value = this.originalValue;
    Autonumeric.getAutoNumericElement(this.inputTarget).set(this.originalValue);
    this.updateValue();
    this.toggle(true);
  }

  toggle(showEdit) {
    const visibleClass = this.visibleClass;
    const hiddenClass = this.hiddenClass;
    const showTarget = this.showTarget;
    const editTargets = this.editTargets;

    if (showEdit) {
      showTarget.classList.add(visibleClass);
      showTarget.classList.remove(hiddenClass);
      editTargets.forEach(function (item) {
        item.classList.add(hiddenClass);
        item.classList.remove(visibleClass);
      });
    } else {
      showTarget.classList.remove(visibleClass);
      showTarget.classList.add(hiddenClass);
      editTargets.forEach(function (item) {
        item.classList.remove(hiddenClass);
        item.classList.add(visibleClass);
      });
    }
  }

  applyDiscount(event) {
    event.preventDefault();

    const toggleClass = this.toggleDiscountClass;
    const selectedIndex = event.currentTarget.dataset.index;
    let input;
    let discount;

    if (selectedIndex === undefined) {
      return;
    }

    this.discountTargets.forEach((item, i) => {
      if (Number(i) === Number(selectedIndex)) {
        item.classList.toggle(toggleClass);

        input = item.querySelector("input");
        input.checked = !input.checked;
        discount = input.checked ? Number(input.value) : 0;
      } else {
        item.classList.remove(toggleClass);

        input = item.querySelector("input");
        input.checked = false;
      }
    });

    this.updatedValue = (this.originalValue * (1 - discount / 100)).toFixed(2);
    this.inputTarget.value = this.updatedValue;
    Autonumeric.getAutoNumericElement(this.inputTarget).set(this.updatedValue);
  }

  updateValue() {
    // decimal separator could be comma
    this.updatedValue = Number(
      this.inputTarget.value
        .replace(new RegExp(`[^0-9${this.decimalCharacter}-]+`, 'g'), "")
        .replace(/,/, '.')
    );

    const toggleClass = this.toggleDiscountClass;
    this.discountTargets.forEach((item) => {
      item.classList.remove(toggleClass);
      item.querySelector("input").checked = false;
    });
  }

  get visibleClass() {
    return this.data.get("visibleClass");
  }

  get toggleDiscountClass() {
    return this.data.get("toggleDiscountClass");
  }

  get hiddenClass() {
    return this.data.get("hiddenClass");
  }

  get decimalCharacter() {
    return this.data.get("decimalCharacter");
  }
}
