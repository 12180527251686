import { Controller } from 'stimulus'
import _ from 'underscore';

export default class extends Controller {
  static targets = ["organizationsField"];

  initialize() {
    this.onEmailChanged = _.debounce(this.onEmailChanged.bind(this), 200);
  }

  onEmailChanged() {
    if (this.hasOrganizationsFieldTarget) {
      this.organizationsFieldTarget.remove();
    }
  }

  onEmailInput() {
    this.onEmailChanged();
  }
}
