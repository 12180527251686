import { Controller } from "stimulus"
import "slick-carousel/slick/slick.js";

export default class extends Controller {
  static targets = ["carouselLondon", "carouselVienna"]
  connect() {
    if (this.hasCarouselViennaTarget) {
      this.slider(this.carouselViennaTarget)
    }else{
      this.slider(this.carouselLondonTarget)
    }
  }

  slider(carousel){
    $(carousel).slick({
      infinite: true,
      slidesToShow: 4,
      arrows: true,
      slidesToScroll: 1,
      'prevArrow': '<button class="slide-arrow prev-arrow"></button>',
      'nextArrow': '<button class="slide-arrow next-arrow"></button>',
      responsive: [
        {
          breakpoint: 913,
          settings: {
            arrows: false,
            centerPadding: '40px',
            slidesToShow: 2
          }
        },
        {
          breakpoint: 550,
          settings: {
            arrows: false,
            centerPadding: '40px',
            slidesToShow: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            arrows: false,
            centerPadding: '40px',
            slidesToShow: 1
          }
        }
      ]    
    });
  }
}