import { Controller } from "stimulus";
import { useDispatch } from "stimulus-use";
import getCsrfToken from "../utils/csrf";

export default class extends Controller {
  static targets = ["modal", "form"];
  static validationTimer;

  connect() {
    useDispatch(this);
    this.initializeControls();
    this.startValidationMonitor();

    this.onAccountUpdate = this.onAccountUpdate.bind(this);
    document.addEventListener("pos/stripe_account_updated", this.onAccountUpdate, false);
  }

  initializeControls() {
    $(this.modalTarget).on("hidden.bs.modal", () => {
      clearTimeout(this.validationTimer);
      this.modalTarget.remove();
    });
  }

  startValidationMonitor() {
    const that = this;
    this.validationTimer = setInterval(() => {
      document
        .querySelectorAll("#terms_section, #organization_section, #persons_section, #payouts_section")
        .forEach((section) => {
          that.updateSectionIndicator(section);
        });
    }, 300);
  }

  updateSectionIndicator(section) {
    const validatedInputs = Array.from(section.querySelectorAll('[data-validate="true"]'));
    const filled = validatedInputs.every((input) => {
      return !!input.value;
    });
    const sectionHeader = document.querySelector(`.section__header[data-target="#${section.getAttribute("id")}"]`);
    if (filled) {
      section.querySelectorAll(".modal__offer .input__error").forEach((errorHolder) => {
        errorHolder.innerHTML = "";
        errorHolder.classList.add("is-hidden");
      });
      sectionHeader.classList.remove("invalid");
      sectionHeader.classList.add("filled");
    } else {
      sectionHeader.classList.remove("filled");
    }
  }

  onAccountUpdate() {
    $("#modal__organization").modal("hide");
    $("#modal__address").modal("hide");
    $("#modal__external-accounts_add").modal("hide");
    $(".modal__person").modal("hide");
    this.refreshForm();
  }

  refreshForm() {
    const form = this.formTarget;
    const openSection = $(".section.show")[0];
    let promise = fetch(this.refreshUrl, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": getCsrfToken(),
      },
      body: JSON.stringify({
        open_section: openSection && openSection.id,
      }),
    });
    promise.then((response) => response.text()).then((html) => (form.innerHTML = html));
    return promise;
  }

  get refreshUrl() {
    return this.data.get("refresh-url");
  }
}
