export function fadeOut({ element, remove, time }) {
  const fadeEffect = setInterval(() => {
    if (!element.style.opacity) element.style.opacity = 1;

    if (element.style.opacity > 0) {
      element.style.opacity -= 0.1;
    } else {
      if (remove) element.remove();

      clearInterval(fadeEffect);
    }
  }, time || 200);
}
