import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["checked", "selectCount", "select", "container", "checkRequestType"];
  static values = {
    placeholder: String,
    selected: String,
  };

  connect() {
    this.checkOptions();
  }

  checkOptions() {
    let count = this.checkedTargets.filter((check) => check.checked).length;
    if (this.checkRequestTypeTarget.value === "true") {
      switch (this.checkRequestTypeTarget.dataset.filter) {
        case "industry":
          const industry = $("#Industry")[0];
          const industry_count = $("#industry-count")[0];
          const industry_container = $("#industry-container")[0];
          this.addClasses(count, industry, industry_container, industry_count);
          break;
        case "concept":
          const concept = $("#Concept")[0];
          const concept_count = $("#concept-count")[0];
          const concept_container = $("#concept-container")[0];
          this.addClasses(count, concept, concept_container, concept_count);
          break
        case "location_type":
          const location_type = $("#location_type")[0];
          const location_types_count = $("#location-types-count")[0];
          const location_type_container = $("#location-type-container")[0];
          this.addClasses(count, location_type, location_type_container, location_types_count);
          break;
      }
    } else {
      this.addClasses(count, this.selectTarget, this.containerTarget, this.selectCountTarget);
    }

    if ($("." + this.selectTarget.id + "__text")[0]) {
      this.toggleClasses(count >= 1);
    }
  }

  addClasses(count, filter, container, filter_count) {
    const selectionCount = this.checkedTargets.filter(check => check.checked).length;

    if (count >= 1) {
      if (!filter.classList.contains("homepage-filter")) {
        filter.innerText = this.selectedValue;
        filter_count.innerText = selectionCount;
        filter.classList.remove("text-gray-800");
        filter.classList.add("text-green-brand");
        filter.classList.add("font-extrabold");
        container.classList.add("md:!border-2");
        container.classList.remove("border-gray-300");
        container.classList.add("border-green-brand");
        container.classList.remove("font-extrabold");
      } else {
        filter_count.innerText = this.selectedValue;
        filter_count.classList.remove("placeholder");

        let counter = filter_count.parentNode.querySelector(".counter");
        if (!counter) {
          const arrow = filter_count.parentNode.querySelector("i");
          counter = document.createElement("span");
          counter.classList.add("counter");
          filter_count.parentNode.insertBefore(counter, arrow);
        }

        counter.innerText = selectionCount;
      }
    } else {
      filter.classList.add("text-gray-800");
      filter.classList.remove("text-green-brand");
      filter.classList.remove("font-extrabold");
      filter.classList.add("font-extrabold");
      container.classList.add("border-gray-300");
      container.classList.remove("border-green-brand");
      container.classList.remove("md:!border-2");

      if (filter.classList.contains("homepage-filter")) {
        if (filter_count.parentNode.querySelector(".counter") != null) {
          filter_count.parentNode.querySelector(".counter").remove();
        }
        filter_count.innerText = this.placeholderValue;
        filter_count.classList.add("placeholder");
      } else {
        filter_count.innerText = "";
        filter.innerText = this.placeholderValue;
      }
    }
  }

  toggleIcon(event) {
    let icon = $(`.icon-${event.currentTarget.id}`);
    icon.toggleClass("text-gray-400");
    icon.toggleClass("text-green-brand");
  }

  toggleClasses(count) {
    const targeted_text = $("." + this.selectTarget.id + "__text")[0];
    const targeted_counter = $("." + this.selectTarget.id + "__count")[0];
    const targeted_container = $("." + this.selectTarget.id + "__container")[0];
    if (count) {
      targeted_text.innerText = this.selectedValue;
      targeted_text.classList.remove("text-gray-800");
      targeted_text.classList.add("text-green-brand");
      targeted_text.classList.add("font-extrabold");
      targeted_container.classList.add("!border-2");
      targeted_container.classList.remove("border-gray-300");
      targeted_container.classList.add("border-green-brand");
      targeted_text.classList.remove("w-full");
      targeted_text.classList.remove("font-extrabold");
      targeted_counter.innerText = this.checkedTargets.filter((check) => check.checked).length;
    } else {
      targeted_text.classList.add("text-gray-800");
      targeted_text.classList.remove("text-green-brand");
      targeted_text.innerText = this.placeholderValue;
      targeted_text.classList.remove("font-extrabold");
      targeted_text.classList.add("font-extrabold");
      targeted_container.classList.add("border-gray-300");
      targeted_container.classList.remove("border-green-brand");
      targeted_text.classList.add("w-full");
      targeted_container.classList.remove("!border-2");
      targeted_counter.innerText = "";
    }
  }
}
