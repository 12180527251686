import {Controller} from 'stimulus'

export default class extends Controller {
  static targets = ["input"]

  connect() {
    this.inputTarget.addEventListener('change', (event) => {
      this.inputTarget.closest('form').dispatchEvent(new Event('submit', {bubbles: true}));
    })
  }
}
