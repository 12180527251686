import { Controller } from 'stimulus'

export default class extends Controller {

  fetchData(event) {
    event.preventDefault();
    const url =  event.currentTarget.href
    const that = this
    $.ajax({
      url: url,
      headers: {          
        Accept: "application/json",
      },
      success: function (res) {
        var $elem = $(`#${res.id}__show`);
        var $pagination_nav = $(`#${res.id} #pagination`);

        $elem.html(res.collections);
        $pagination_nav.html(res.pagination);
        
        that.scroll(res.id)

      }
    });
  }

  scroll(id) {
    var offset = $(`#${id}`).offset();
        $('html, body').animate({
            scrollTop: offset.top,
            scrollLeft: offset.left
        }, 1000);

  }
}