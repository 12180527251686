import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["link"];

  connect() {
    this.scrollToEnd();
    this.showFooter();
  }

  scrollToEnd() {
    const i = document.querySelectorAll(".timeline__body div").length - 1;
    const lastTimelineElement = document.querySelectorAll(".timeline__body div")[i];
    lastTimelineElement.scrollIntoView();
  }

  showFooter() {
    const footer = document.querySelector(".timeline__footer");
    footer.classList.remove("hidden");
  }

  scrollToWidget() {
    const widgetElementId = this.linkTarget.getAttribute("data-widget-id");
    const widget = document.querySelector(`#${widgetElementId}`);
    widget.scrollIntoView();
  }

  scrollToAndOpenOfferWidget() {
    const widget = document.querySelector("#inquiry_widget");
    widget.scrollIntoView();
    const openWidgetBtn =
      document.querySelector("#timeline_send_offer") || document.querySelector("#timeline_confirmed_booking");
    if (openWidgetBtn) {
      openWidgetBtn.click();
    }
  }

  disconnect() {}
}
