import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    'form',
    'card',
    'errors',
    'submitButton',
  ]

  connect() {
    this.stripe = Stripe(this.data.get('key'), { locale: this.data.get('locale') })
    const elements = this.stripe.elements()
    const style = JSON.parse(this.data.get('style'))

    this.card = elements.create('card', { style: style })
    this.card.mount(this.cardTarget)
    this.card.on('change', (event) => this.change(event))
  }

  change(event) {
    this.errorsTarget.textContent = this.displayedMessage(event)
    this.errorsTarget.classList.toggle(this.data.get('error-class'), Boolean(event.error))
  }

  displayedMessage(event) {
    if (event.error) {
      return event.error.message
    } else {
      return "\u00A0" // the non-brekable space preserves the layout
    }
  }

  submit(event) {
    event.preventDefault()

    this.submitButtonTarget.disabled = true

    const clientSecret = this.data.get('secret')

    this.stripe.confirmCardSetup(
      clientSecret,
      {
        payment_method: {
          card: this.card,
        },
      }
    ).then(function(result) {
      if (result.error) {
        console.log(result.error)
      } else {
        console.log(result.setupIntent)
        this.formTarget.submit() // does not trigger onSubmit, avoiding infinite loop
      }
    }.bind(this))
  }
}
