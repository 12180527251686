/**
 * Select2.js variants
 */

import $ from "jquery";
import "select2/dist/js/select2.full.js";
import "../utils/select2-multi-checkboxes.js";

export default function select2() {
  //override behaviour
  $.fn.select2.amd.require(
    ["select2/dropdown/attachBody"],
    function (AttachBody) {
      // simple override
      AttachBody.prototype._positionDropdown = function () {
        var $window = $(window);

        var isCurrentlyAbove = this.$dropdown.hasClass(
          "select2-dropdown--above"
        );
        var isCurrentlyBelow = this.$dropdown.hasClass(
          "select2-dropdown--below"
        );

        var newDirection = null;

        var offset = this.$container.offset();

        offset.bottom = offset.top + this.$container.outerHeight(false);

        var container = {
          height: this.$container.outerHeight(false),
        };

        container.top = offset.top;
        container.bottom = offset.top + container.height;

        var dropdown = {
          height: this.$dropdown.outerHeight(false),
        };

        var viewport = {
          top: $window.scrollTop(),
          bottom: $window.scrollTop() + $window.height(),
        };

        var enoughRoomAbove = viewport.top < offset.top - dropdown.height;
        var enoughRoomBelow = viewport.bottom > offset.bottom + dropdown.height;

        var containerWidth = this.$container.outerWidth();
        var right = $("body").outerWidth() - (offset.left + containerWidth);

        if (this.$element.hasClass("right-align")) {
          var css = {
            right: right,
            top: container.bottom,
          };
        } else {
          var css = {
            left: offset.left,
            top: container.bottom,
          };
        }

        // Determine what the parent element is to use for calculating the offset
        var $offsetParent = this.$dropdownParent;

        // For statically positioned elements, we need to get the element
        // that is determining the offset
        if ($offsetParent.css("position") === "static") {
          $offsetParent = $offsetParent.offsetParent();
        }

        var parentOffset = {
          top: 0,
          left: 0,
        };

        if (
          $.contains(document.body, $offsetParent[0]) ||
          $offsetParent[0].isConnected
        ) {
          parentOffset = $offsetParent.offset();
        }

        css.top -= parentOffset.top;
        css.left -= parentOffset.left;

        if (!isCurrentlyAbove && !isCurrentlyBelow) {
          newDirection = "below";
        }

        if (!enoughRoomBelow && enoughRoomAbove && !isCurrentlyAbove) {
          newDirection = "above";
        } else if (!enoughRoomAbove && enoughRoomBelow && isCurrentlyAbove) {
          newDirection = "below";
        }

        if (
          newDirection == "above" ||
          (isCurrentlyAbove && newDirection !== "below")
        ) {
          css.top = container.top - parentOffset.top - dropdown.height;
        }

        if (newDirection != null) {
          this.$dropdown
            .removeClass("select2-dropdown--below select2-dropdown--above")
            .addClass("select2-dropdown--" + newDirection);
          this.$container
            .removeClass("select2-container--below select2-container--above")
            .addClass("select2-container--" + newDirection);
        }

        this.$dropdownContainer.css(css);
      };
    },
    undefined,
    true
  );

  //
  // const $select2Dropdown = $('[data-js="select2-select"]');
  //
  // if ($select2Dropdown.length > 0) {
  //   $select2Dropdown.select2({
  //     minimumResultsForSearch: Infinity,
  //   });
  // }
  //
  // const $select2DropdownMulti = $('[data-js="select2-multiselect"]');
  //
  // if ($select2DropdownMulti.length > 0) {
  //   $select2DropdownMulti.select2({
  //     minimumResultsForSearch: Infinity,
  //   });
  // }
  //
  // const $select2DropdownMultiCollapse = $(
  //   '[data-js="select2-multiselect-collapse"]'
  // );
  //
  // if ($select2DropdownMultiCollapse.length > 0) {
  //   $select2DropdownMultiCollapse.select2({
  //     minimumResultsForSearch: Infinity,
  //     closeOnSelect: false,
  //   });
  //   $select2DropdownMultiCollapse.on("change", function (event) {
  //     $(event.currentTarget)
  //       .next()
  //       .find(".select2-selection__rendered li:not(.select2-search--inline)")
  //       .hide();
  //     $(event.currentTarget).next().find(".counter").remove();
  //     var counter = $(event.currentTarget)
  //       .next()
  //       .find(".select2-selection__choice").length;
  //     $(event.currentTarget)
  //       .next()
  //       .find(".select2-selection__rendered")
  //       .hide()
  //       .after(
  //         '<span class="counter select2-selection__rendered"> Selected <span class="counter__pill">' +
  //           counter +
  //           "</span></span>"
  //       );
  //   });
  //   $select2DropdownMultiCollapse.trigger("change");
  // }
  //
  // const $select2DropdownMultiCheckbox = $(
  //   '[data-js="select2-multiselect-checkbox"]'
  // );
  //
  // if ($select2DropdownMultiCheckbox.length > 0) {
  //   $select2DropdownMultiCheckbox.select2MultiCheckboxes({
  //     templateSelection: function (selected, total) {
  //       return "Selected " + selected.length + " of " + total;
  //     },
  //   });
  // }
}
