import { Controller } from "stimulus";
import $ from "jquery";

export default class extends Controller {
  initialize() {
    this.currentPageIndex = 0;
    this.timeout = null;
  }

  page(event) {
    const element = event.currentTarget;
    this.currentPageIndex = element.dataset.pageIndex;
    this.load();
  }

  load() {
    const filterPrefix = this.data.get("filter");
    const urlElements = document.querySelectorAll("[name^='" + filterPrefix + "']");
    const paramsJSON = {};

    urlElements.forEach((e) => {
      let value, name;
      name = e.name.replace(filterPrefix, "").replaceAll("[", "").replaceAll("]", "");
      if (e.nodeName === "SELECT") {
        const selected = [];
        for (let option of e.options) {
          if (option.selected && !!option.value) {
            selected.push(option.value);
          }
        }
        value = selected;
      } else if (name === "location_type") {
        let selected = []
        if (e.type === "checkbox") {
          for (let option of $("#search_location_type :checkbox:checked")) {
            if (option.checked && !!option.value) {
              selected.push(option.value);
            }
          }
          value = selected
        } else {
          value = e.value.split(" ");
        }

      } else if (e.type === "checkbox") {
        const selected = [];

        if (name === "space_usage_type") {
          for (let option of $("#search_space_usage_type_ :checkbox:checked")) {
            if (option.checked && !!option.value) {
              selected.push(option.value);
            }
          }

          for (let option of $("#search_space_usage_type_homepage :checkbox:checked")) {
            if (option.checked && !!option.value) {
              selected.push(option.value);
            }
          }
        }

        if (name === "industry") {
          for (let option of $("#search_industry :checkbox:checked")) {
            if (option.checked && !!option.value) {
              selected.push(option.value);
            }
          }
        }

        value = selected;
      } else if (e.type !== "checkbox") {
        value = e.value;
      }
      if (Array.isArray(value) && !value.length) return;
      paramsJSON[name] = value;
    });

    const filterParams = new URLSearchParams(Object.entries(paramsJSON)).toString();
    let newUrl = new URL(this.data.get("url"));
    newUrl.searchParams.set("page", this.currentPageIndex);
    if (paramsJSON.page_name == "search" && event.type == "pos/places_changed") {
      let locationUrl = new URL(this.data.get("location"));
      this.searchLocation(locationUrl, filterParams, paramsJSON);
    } else {
      this.debounce(() => this.applyFilters(newUrl, filterParams, paramsJSON), 500)();
    }
  }

  debounce(func, wait) {
    let timeout = this.timeout;
    return (...args) => {
      clearTimeout(timeout);
      this.timeout = setTimeout(() => func.apply(this, args), wait);
    };
  }


  applyFilters(newUrl, filterParams, paramsJSON) {
    fetch(`${newUrl}&${filterParams}`, { method: this.method })
      .then((response) => response.text())
      .then((html) => {
        // `id` is required because it's used to
        // known the target as well.
        let $newEl = $(html);
        let id = $newEl.attr("id");
        let $el = $(`#${id}`);

        if (paramsJSON.page_name == "search") {
          let listing_id = $newEl[0].id;
          let map_id = $newEl[2].id;

          let $listing_el = $(`#${listing_id}`);
          let $map_el = $(`#${map_id}`);

          if ($listing_el.length) {
            $listing_el.replaceWith($newEl[0]);
          }
          if ($map_el.length) {
            $map_el.replaceWith($newEl[2]);
          }
        } else if ($el.length) {
          $el.replaceWith($newEl);
          let listing_count = $newEl[0].getElementsByClassName("listings__count")[0].innerHTML;
          if (document.getElementById("listing__heading") && document.getElementsByClassName("listings__total").length != 0) {
            document.getElementsByClassName("listings__total")[0].innerText = `(${listing_count})`;
          }
        }
      });
  }

  searchLocation(locationUrl, filterParams, paramsJSON) {
    fetch(locationUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(paramsJSON),
    })
      .then((response) => response.text())
      .then((html) => {
        let url = window.location.pathname.split("/");
        url.pop();
        url.push(JSON.parse(html).data.uuid);
        url = url.join("/");
        window.location = url;
      });
  }

  teardown() {}

  get method() {
    return this.data.get("method") || "GET";
  }
}
