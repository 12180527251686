import { Controller } from "stimulus";
import $ from "jquery";
import _ from "underscore";

export default class extends Controller {
  static targets = ["sidebar"];

  connect() {
    this.onResize = _.debounce(this.onResize.bind(this), 100);
    window.addEventListener("resize", this.onResize, false);
  }

  onResize() {
    if ($(window).width() > this.maxWidth) {
      if ($(this.sidebarTarget).hasClass("show")) {
        $(this.sidebarTarget).modal("hide");
        $(".modal-backdrop").hide();
      }

      $(this.sidebarTarget).removeAttr("style");
    }
  }

  get maxWidth() {
    return parseInt(this.data.get('maxWidth'));
  }
}
