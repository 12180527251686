import { Controller } from 'stimulus'
import $ from "jquery";

import { uploadDone } from "../events/upload";

export default class extends Controller {
  static targets = ["input", "preview", "uploadLink", "updateLinks", "previewIcon"];

  connect() {
    const url = this.data.get('url');
    const imageTypes = /(\.|\/)(gif|jpe?g|png)$/i;
    const formDataMethod = JSON.parse(this.data.get('form-data'));

    const $el = $(this.inputTarget);
    const $preview = $(this.previewTarget);

    $el.fileupload({
      url: url,
      uploadTemplateId: null,
      downloadTemplateId: null,
      dataType: 'json',
      autoUpload: true,
      maxNumberOfFiles: 1,
      acceptFileTypes: imageTypes,
      maxFileSize: 25000000 // 25 MB
    }).bind('fileuploaddone', function(e, data) {
      const fileUrl = data.response().result.files[0].url;
      $preview.css("background", `url(${fileUrl})`).removeClass("empty");
      if (this.hasUploadLinkTarget) {
        $(this.uploadLinkTarget).hide();
      }
      if (this.hasUpdateLinksTarget) {
        $(this.updateLinksTarget).show();
      }
      if (this.hasPrerviewIconTarget) {
        this.previewIconTarget.remove();
      }
      uploadDone();
    }).bind('fileuploadsubmit', function(e, data) {
      data.formData = formDataMethod;
    });
  }

  removeUploadedImage() {
    $.ajax({
      url: this.data.get('url'),
      type: 'PUT',
      dataType: 'json',
      // change logo to attribute name from logo_uploader_component.rb
      data: { logo: null }
    }).done(() => {
      $(this.previewTarget).css("background", "").addClass("empty");
      $(this.uploadLinkTarget).show();
      $(this.updateLinksTarget).hide();
    });
  }
}