import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["map", "radioButton"];
  connect() {}

  toggleMap(event) {
    event.preventDefault();
    const mapView = this.radioButtonTarget.checked;

    if (this.updateUrl) {
      fetch(this.updateUrl, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ map_view: mapView }),
      });
    }

    if (mapView) {
      this.mapTarget.classList.remove("hidden");
      $("#location-map").css({ height: "", width: "" });
    } else {
      this.mapTarget.classList.add("hidden");
    }
  }

  get updateUrl() {
    return this.data.get("updateUrl");
  }
}
