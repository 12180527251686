import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['topic','booking','listing','listingOptions','bookingOptions']
 
  optionChanged(){
    var topic =this.topicTarget.value;
    if (topic.substring(0, 'listing'.length) == 'listing') {
       this.show_hide_options(true, false);

    } else if (topic.substring(0, 'booking'.length) == 'booking') {
       this.show_hide_options(false, true);
    } else {
       this.show_hide_options(false, false);
    }
  }

  show_hide_options(enable_listings,enable_bookings){
    if (enable_listings) {
      this.listingTarget.classList.remove('hidden');
      this.listingOptionsTarget.removeAttribute("disabled");
    } else {
      this.listingOptionsTarget.setAttribute("disabled", "disabled");
      this.listingTarget.classList.add('hidden');
    }

    if (enable_bookings) {
      this.bookingTarget.classList.remove('hidden');
      this.bookingOptionsTarget.removeAttribute("disabled");
    } else {
      this.bookingOptionsTarget.setAttribute("disabled", "disabled");
      this.bookingTarget.classList.add('hidden');
    }
  }

}