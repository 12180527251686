import $ from "jquery";
import { Controller } from "stimulus";
import moment from "moment";
import _ from "underscore";
import { t } from "../i18n/t";

export default class extends Controller {
  static targets = [
    "form",
    "errorsHolder",
    "submitButton",
    "personToken",
    "firstName",
    "lastName",
    "email",
    "phone",
    "dob",
    "relationshipTitle",
    "relationshipRepresentative",
    "relationshipOwner",
    "relationshipDirector",
    "relationshipExecutive",
    "addressLine1",
    "addressLine2",
    "addressPostalCode",
    "addressCity",
    "addressState",
    "addressCountry",
    "ssnLast4",
    "idNumber",
    "documentFront",
    "documentBack",
    "documentAdditional",
  ];

  initialize() {
    this.validateSsnLast4 = _.debounce(this.validateSsnLast4.bind(this), 200);
  }

  connect() {
    this.stripe = Stripe(this.data.get("stripe-key"), {
      locale: this.data.get("locale"),
    });
  }

  submitStripe(event) {
    event.preventDefault();

    const that = this;

    this.resetFormErrors();

    const person = this.isDocumentsForm ? this.documents : this.person;

    this.stripe
      .createToken("person", { person: person })
      .then((personResult) => {
        if (personResult.token) {
          $.ajax({
            type: that.method,
            url: that.formTarget.action,
            global: true,
            dataType: "script",
            data: { person: person, person_token: personResult.token.id, stripe_sidebar: that.stripeSidebar },
          });
        } else {
          that.handleStripeError(personResult.error);
        }
      })
      .catch((err) => console.log(err.message));

    return false;
  }

  handleStripeError({ param, code, message }) {
    if (param) {
      if (code === "parameter_missing") {
        message = "Missing required field.";
      }

      this.displayParamStripeError(param, message);
    } else {
      this.displayGeneralStripeError(message);
    }

    this.submitButtonTarget.disabled = false;
  }

  displayParamStripeError(param, message) {
    if (param.match(/person\[dob]/)) {
      param = "person[dob][date]";
    }

    const $input = $(this.formTarget).find("[name='" + param + "']");

    if ($input) {
      const $field = $($input.closest(".field"));
      const $errorContainer = $field.find(".input__error_container");

      $input.addClass("input__danger");
      $errorContainer.html(`<div class="input__error">${message}</div>`);
    } else {
      this.displayGeneralStripeError(message);
    }
  }

  displayGeneralStripeError(message) {
    const $errorsHolder = $(this.errorsHolderTarget);

    $errorsHolder.html(`<span class="input__error_container"><div class="input__error">${message}</div></span>`);
    $errorsHolder.show();
  }

  resetFormErrors() {
    $(this.errorsHolderTarget).hide();

    $(".input__error_container").empty();
    $(".field select").removeClass("input__danger");
    $(".field input").removeClass("input__danger");

    this.submitButtonTarget.disabled = true;
  }

  validateDocuments() {
    const documentsUploaded =
      (this.documentFrontTarget.dataset.requested === "false" || !!this.documentFrontTarget.value) &&
      (this.documentBackTarget.dataset.requested === "false" || !!this.documentBackTarget.value) &&
      (this.documentAdditionalTarget.dataset.requested === "false" || !!this.documentAdditionalTarget.value);
    this.submitButtonTarget.disabled = !documentsUploaded;
  }

  validateSsnLast4() {
    const ssnLast4 = this.ssnLast4Target.value;

    if (/^(|\d{4})$/.test(ssnLast4)) {
      this.submitButtonTarget.disabled = false;
      $(".input__error_container").empty();
      $(this.ssnLast4Target).removeClass("input__danger");
    } else {
      this.submitButtonTarget.disabled = true;
      this.displayParamStripeError("person[ssn_last_4]", t("stripe.persons.invalid_ssn_last_4"));
    }
  }

  onSsnLast4Input() {
    this.validateSsnLast4();
  }

  get documents() {
    return {
      verification: {
        document: {
          front: this.documentFrontTarget.value,
          back: this.documentBackTarget.value,
        },
        additional_document: {
          front: this.documentAdditionalTarget.value,
        },
      },
    };
  }

  get person() {
    const person = {
      first_name: this.firstNameTarget.value,
      last_name: this.lastNameTarget.value,
      email: this.emailTarget.value,
      phone: this.phoneTarget.value,
      relationship: this.relationship,
      address: {
        line1: this.addressLine1Target.value,
        line2: this.addressLine2Target.value,
        postal_code: this.addressPostalCodeTarget.value,
        city: this.addressCityTarget.value,
        state: this.addressStateTarget.value,
        country: this.addressCountryTarget.value,
      },
    };

    if (this.dobTarget.value !== "") {
      const date = moment(this.dobTarget.value, this.dateFormat);

      person.dob = {
        day: date.format("D"),
        month: date.format("M"),
        year: date.format("YYYY"),
      };
    }

    if (this.hasSsnLast4Target && this.ssnLast4Target.value !== "") {
      person.ssn_last_4 = this.ssnLast4Target.value;
    }

    if (this.hasIdNumberTarget && this.idNumberTarget.value !== "") {
      person.id_number = this.idNumberTarget.value;
    }

    return person;
  }

  get relationship() {
    return {
      title: this.relationshipTitleTarget.value,
      ...(this.isIndividualBusinessType ? this.individualRelationship : this.companyRelationship),
    };
  }

  get companyRelationship() {
    return {
      representative: this.relationshipRepresentativeTarget.checked,
      owner: this.relationshipOwnerTarget.checked,
      director: this.relationshipDirectorTarget.checked,
      executive: this.relationshipExecutiveTarget.checked,
    };
  }

  get individualRelationship() {
    return { representative: true };
  }

  get stripeSidebar() {
    return this.data.get("stripe-sidebar") === "true";
  }

  get dateFormat() {
    return this.data.get("dateFormat").toUpperCase();
  }

  get method() {
    return this.data.get("method");
  }

  get isDocumentsForm() {
    return this.data.get("documents-form");
  }

  get isIndividualBusinessType() {
    return this.data.get("individual-type") === "true";
  }
}
