import $ from "jquery";
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "form",
    "errorsHolder",
    "submitButton",
    "country",
    "currency",
    "routingNumber",
    "accountNumber",
    "accountHolderName",
    "accountHolderType",
  ];

  connect() {
    this.stripe = Stripe(this.data.get("stripe-key"), {
      locale: this.data.get("locale"),
    });
  }

  submitStripe(event) {
    event.preventDefault();

    const that = this;

    this.resetFormErrors();

    this.stripe
      .createToken("bank_account", that.externalAccount)
      .then((accountResult) => {
        if (accountResult.token) {
          $.ajax({
            type: "POST",
            url: that.formTarget.action,
            global: true,
            data: {
              external_account_token: accountResult.token.id,
              external_account: that.externalAccount,
              stripe_sidebar: that.stripeSidebar,
            },
          });
        } else {
          that.handleStripeError(accountResult.error);
        }
      })
      .catch((err) => console.log(err.message));

    return false;
  }

  handleStripeError({ param, code, message }) {
    if (param) {
      if (code === "parameter_missing") {
        message = "Missing required field.";
      }

      this.displayParamStripeError(param, message);
    } else {
      this.displayGeneralStripeError(message);
    }

    this.submitButtonTarget.disabled = false;
  }

  displayParamStripeError(param, message) {
    const $input = $(this.formTarget).find("[name='" + param + "']");
    const $field = $($input.closest(".field"));
    const $errorContainer = $field.find(".input__error_container");

    $input.addClass("input__danger");
    $errorContainer.html(`<div class="input__error">${message}</div>`);
  }

  displayGeneralStripeError(message) {
    const $errorsHolder = $(this.errorsHolderTarget);

    $errorsHolder.html(`<span class="input__error_container"><div class="input__error">${message}</div></span>`);
    $errorsHolder.show();
  }

  resetFormErrors() {
    $(this.errorsHolderTarget).hide();

    $(".input__error_container").empty();
    $(".field select").removeClass("input__danger");
    $(".field input").removeClass("input__danger");

    this.submitButtonTarget.disabled = true;
  }

  get stripeSidebar() {
    return this.data.get("stripe-sidebar") === "true";
  }

  get externalAccount() {
    return {
      country: this.countryTarget.value,
      currency: this.currencyTarget.value,
      account_holder_type: this.accountHolderTypeTarget.value,
      ...(!!this.accountNumberTarget.value && {
        account_number: this.accountNumberTarget.value,
      }),
      ...(!!this.routingNumberTarget.value && {
        routing_number: this.routingNumberTarget.value,
      }),
      ...(!!this.accountHolderNameTarget.value && {
        account_holder_name: this.accountHolderNameTarget.value,
      }),
    };
  }
}
