import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["enInput", "deInput", "frInput", "itInput", "localeNamePlaceholder"];

  static values = {
    enName: String,
    deName: String,
    frName: String,
    itName: String,
  };

  showLocaleInput(e) {
    e.preventDefault();

    const locale = e.target.dataset.locale;
    this[`${locale}InputTarget`].parentElement.classList.remove("d-none");

    const otherLocales = ["en", "fr", "de", "it"].filter((l) => l !== locale);
    this.hideLocaleInputs(otherLocales);

    this.localeNamePlaceholderTarget.text = this[`${locale}NameValue`];
  }

  hideLocaleInputs(locales) {
    locales.forEach((locale) => {
      if (this[`has${this.capitalize(locale)}InputTarget`]) {
        this[`${locale}InputTarget`].parentElement.classList.add("d-none");
      }
    });
  }

  capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
}
